import React, { FC } from 'react';
import styles from './ProfilePicture.module.css';

const ProfilePicture: FC = () => {
    const firstName = "Gilad";
    const lastName = "Ezra";
    const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

    return (
        <div className={styles.profileCircle}>
            {initials}
        </div>
    );
};

export default ProfilePicture;
