// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__JQcOj {
    display: flex;
    background: rgba(218, 216, 227, 0.4);
    background-blend-mode: multiply;
    border-radius: 23px;
    height: 2.5rem;
    align-items: center;
    padding: 0.5rem;
}

.Search_search__JQcOj * {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}

.Search_search__JQcOj input {
    background-color: transparent;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/Search/Search.module.css"],"names":[],"mappings":"AACI;IAAA,aAAW;IACX,oCAAoC;IACpC,+BAA+B;IAC/B,mBAAmB;IACnB,cAAW;IACX,mBAAmB;IACnB;AANW;;AAUX;IAAA,oBAAW;IAAX;AAAW;;AAGf;IACI,6BAA6B;IAC7B,aAAa;AACjB","sourcesContent":[".search {\n    @apply flex;\n    background: rgba(218, 216, 227, 0.4);\n    background-blend-mode: multiply;\n    border-radius: 23px;\n    @apply h-10;\n    @apply items-center;\n    @apply p-2;\n}\n\n.search * {\n    @apply mx-1;\n}\n\n.search input {\n    background-color: transparent;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__JQcOj`
};
export default ___CSS_LOADER_EXPORT___;
