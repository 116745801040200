import React, { FC } from "react";
import TopMenuDropdown from "../BusinessesDropdown/BusinessesDropdown";
import Search from "../Search/Search";
import Notifications from "../Notifications/Notifications";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import styles from './TopMenuContainer.module.css'; // Importing the CSS module

const TopMenuContainer: FC = () => {
    return (
        <div className={styles.topNav}>
            <span className={styles.viewingDataText}>Viewing Data for:</span>
            <TopMenuDropdown />
            <div className={styles.topNavSearchAndIcons}>
                <Search />
                <Notifications />
                <ProfilePicture />
            </div>
        </div>
    );
}

export default TopMenuContainer;
