import React, {FC} from "react";
import {ReactComponent as SearchIcon} from "../../../assets/search-icon.svg";
import styles from './Search.module.css';


const Search: FC = function () {
    return <div className={styles.search}>
                <SearchIcon/>
                <input placeholder="Search..."/>
            </div>
}

export default Search;