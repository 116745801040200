import React from 'react';

interface ArchiveButtonProps {
    isArchived: boolean;
    onToggleArchive: () => void;
}

const ArchiveButton: React.FC<ArchiveButtonProps> = ({ isArchived, onToggleArchive }) => {
    return (
        <button onClick={onToggleArchive} style={{ border: 'none', background: 'none', padding: '0' }}>
            {isArchived ? (
                // Filled Archive Icon for Archived State
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive-off" width="24"
                     height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                     stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M8 4h11a2 2 0 1 1 0 4h-7m-4 0h-3a2 2 0 0 1 -.826 -3.822"/>
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 1.824 -1.18m.176 -3.82v-7"/>
                    <path d="M10 12h2"/>
                    <path d="M3 3l18 18"/>
                </svg>

            ) : (
                // Outline Archive Icon for Unarchived State
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-archive" width="24"
                     height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none"
                     stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"/>
                    <path d="M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10"/>
                    <path d="M10 12l4 0"/>
                </svg>
            )}
        </button>
    );
};

export default ArchiveButton;
