// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeatureTabs_tabTitle__CwwaI {
    font-weight: 500; /* Equivalent to 'font-medium' in Tailwind */
    color: #1F2937; /* Equivalent to 'text-gray-800' */
  }`, "",{"version":3,"sources":["webpack://./src/components/RemindersEditor/FeatureTabs/FeatureTabs.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,4CAA4C;IAC9D,cAAc,EAAE,kCAAkC;EACpD","sourcesContent":[".tabTitle {\n    font-weight: 500; /* Equivalent to 'font-medium' in Tailwind */\n    color: #1F2937; /* Equivalent to 'text-gray-800' */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabTitle": `FeatureTabs_tabTitle__CwwaI`
};
export default ___CSS_LOADER_EXPORT___;
