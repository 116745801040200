import React, { FC, useState } from "react";
import { Dropdown } from "flowbite-react";
import { useSelection } from "../../../context/SelectionContext";
import styles from "./PartnerDropdown.module.css";

import { ReactComponent as PartnersIcon } from '../../../assets/partners-icon.svg';


interface PartnerDropdownProps {
  partners: Array<{ id: string; name: string }>;
}

const PartnerDropdown: FC<PartnerDropdownProps> = ({ partners }) => {
  const { selectedPartner, setSelectedPartner } = useSelection();
  const [, setIsOpen] = useState(false);
  const handlePartnerSelection = (partnerId: string) => {
    const partner = partners.find((p) => p.id === partnerId);
    if (partner) {
      setSelectedPartner(partner);
    }
    setIsOpen(false);
  };

  return (
    <div className={styles.dropdownContainer}>
      <Dropdown
        theme={{ floating: { target: styles.dropdownButton, content: styles.dropdownContent } }}
        label={
          <>
            <PartnersIcon className={styles.dropdownIcon} />
            <span>{selectedPartner?.name || "Select partner"}</span>
          </>
        }
      >
        {partners.map((partner) => (
          <Dropdown.Item
            className={styles.dropdownItem}
            key={partner.id}
            onClick={() => handlePartnerSelection(partner.id)}
          >
            {partner.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

export default PartnerDropdown;
