import { useState } from "react";
import { Card, Button, Dropdown, Table, Pagination } from "flowbite-react";
import {ReactComponent as SearchIcon} from "../../assets/search-icon.svg";


interface Product {
  name: string;
  category: string;
  brand: string;
  description: string;
  price: string;
}

const products: Product[] = [
    {
      name: "Apple iPhone 14",
      category: "Phone",
      brand: "Apple",
      description: "Latest model",
      price: "$999",
    },
    {
      name: "Samsung Galaxy S22",
      category: "Phone",
      brand: "Samsung",
      description: "High-resolution camera",
      price: "$800",
    },
    {
      name: "Google Pixel 6",
      category: "Phone",
      brand: "Google",
      description: "Smart AI features",
      price: "$600",
    },
    {
      name: "Dell XPS 13",
      category: "Laptop",
      brand: "Dell",
      description: "Lightweight and powerful",
      price: "$1200",
    },
    {
      name: "MacBook Pro 14",
      category: "Laptop",
      brand: "Apple",
      description: "M1 chip, high performance",
      price: "$1999",
    },
    {
      name: "Sony WH-1000XM4",
      category: "Headphones",
      brand: "Sony",
      description: "Noise-cancelling, wireless",
      price: "$350",
    },
    {
      name: "Bose QuietComfort 35 II",
      category: "Headphones",
      brand: "Bose",
      description: "Comfortable, superior sound quality",
      price: "$300",
    },
    {
      name: "Apple Watch Series 7",
      category: "Smartwatch",
      brand: "Apple",
      description: "Advanced health features",
      price: "$399",
    },
    {
      name: "Fitbit Versa 3",
      category: "Smartwatch",
      brand: "Fitbit",
      description: "Fitness tracking, long battery life",
      price: "$229",
    },
    {
      name: "Samsung Galaxy Watch 4",
      category: "Smartwatch",
      brand: "Samsung",
      description: "Elegant design, health monitoring",
      price: "$250",
    },
    {
      name: "Canon EOS R5",
      category: "Camera",
      brand: "Canon",
      description: "Professional-grade mirrorless camera",
      price: "$3899",
    },
    {
      name: "Nikon Z6",
      category: "Camera",
      brand: "Nikon",
      description: "High resolution, fast shooting",
      price: "$2000",
    },
    {
      name: "GoPro HERO9",
      category: "Camera",
      brand: "GoPro",
      description: "Action camera, waterproof",
      price: "$399",
    },
    {
      name: "Microsoft Surface Pro 8",
      category: "Tablet",
      brand: "Microsoft",
      description: "2-in-1, versatile",
      price: "$1100",
    },
    {
      name: "Apple iPad Pro",
      category: "Tablet",
      brand: "Apple",
      description: "Powerful, with M1 chip",
      price: "$799",
    },
    {
      name: "Sony PlayStation 5",
      category: "Gaming Console",
      brand: "Sony",
      description: "Next-gen gaming experience",
      price: "$499",
    },
    {
      name: "Xbox Series X",
      category: "Gaming Console",
      brand: "Microsoft",
      description: "Powerful performance, high-speed",
      price: "$499",
    },
    {
      name: "Nintendo Switch",
      category: "Gaming Console",
      brand: "Nintendo",
      description: "Portable, family fun",
      price: "$299",
    },
    {
      name: "LG OLED C1",
      category: "Television",
      brand: "LG",
      description: "4K Smart TV, stunning picture quality",
      price: "$1500",
    },
    {
      name: "Samsung QN90A Neo QLED",
      category: "Television",
      brand: "Samsung",
      description: "Bright, vibrant colors",
      price: "$1800",
    },
    {
      name: "Sony A80J",
      category: "Television",
      brand: "Sony",
      description: "OLED, incredible contrast",
      price: "$1300",
    },
    {
      name: "Bose Soundbar 700",
      category: "Sound System",
      brand: "Bose",
      description: "High-quality audio, sleek design",
      price: "$799",
    },
    {
      name: "Sonos Beam",
      category: "Sound System",
      brand: "Sonos",
      description: "Compact, smart soundbar",
      price: "$399",
    },
    {
      name: "JBL Flip 5",
      category: "Speaker",
      brand: "JBL",
      description: "Portable, waterproof",
      price: "$119",
    },
    {
      name: "Dyson V11 Torque Drive",
      category: "Vacuum Cleaner",
      brand: "Dyson",
      description: "Cordless, powerful suction",
      price: "$599",
    },
    {
      name: "iRobot Roomba i7+",
      category: "Vacuum Cleaner",
      brand: "iRobot",
      description: "Robot vacuum, self-emptying",
      price: "$799",
    },
    {
      name: "Samsung Bespoke Refrigerator",
      category: "Appliance",
      brand: "Samsung",
      description: "Customizable, modern design",
      price: "$3000",
    },
    {
      name: "KitchenAid Stand Mixer",
      category: "Appliance",
      brand: "KitchenAid",
      description: "Versatile, multiple attachments",
      price: "$400",
    },
    {
      name: "Nespresso Vertuo Coffee Maker",
      category: "Appliance",
      brand: "Nespresso",
      description: "Espresso and coffee, convenient capsules",
      price: "$199",
    },
    {
      name: "Razer Blade 15",
      category: "Laptop",
      brand: "Razer",
      description: "Gaming laptop, high performance",
      price: "$1800",
    }
  ];

const MyComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalProducts = products.length;
  const productsPerPage = 10; // Adjust as needed
  const totalPages = Math.ceil(totalProducts / productsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    // Additional logic to fetch/load data for the selected page
  };

  // Logic to get the products for the current page
    products.slice(
        (currentPage - 1) * productsPerPage,
        currentPage * productsPerPage
    );
    return (
    <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <Card>
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <form className="flex items-center">
                <div className="relative w-full">
                  <SearchIcon className="absolute inset-y-0 left-0 h-5 w-5 text-gray-500 dark:text-gray-400" />
                  <input
                    type="text"
                    id="simple-search"
                    className="..."
                    placeholder="Search"
                    required
                  />
                </div>
              </form>
            </div>
            <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
              <Button>
                <SearchIcon className="h-3.5 w-3.5 mr-2" />
                Add product
              </Button>
              <Dropdown label="Actions">
                <Dropdown.Item>Action 1</Dropdown.Item>
                <Dropdown.Item>Action 2</Dropdown.Item>
                {/* Add more items as needed */}
              </Dropdown>
              <Dropdown label="Filter">
                <Dropdown.Item>Filter 1</Dropdown.Item>
                <Dropdown.Item>Filter 2</Dropdown.Item>
                {/* Add more items as needed */}
              </Dropdown>
            </div>
          </div>
          <div className="overflow-x-auto">
            <Table hoverable={true}>
              <Table.Head>
                <Table.HeadCell>Product name</Table.HeadCell>
                <Table.HeadCell>Category</Table.HeadCell>
                <Table.HeadCell>Brand</Table.HeadCell>
                <Table.HeadCell>Description</Table.HeadCell>
                <Table.HeadCell>Price</Table.HeadCell>
                <Table.HeadCell>
                  <span className="sr-only">Actions</span>
                </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                {products.map((product, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{product.name}</Table.Cell>
                    <Table.Cell>{product.category}</Table.Cell>
                    <Table.Cell>{product.brand}</Table.Cell>
                    <Table.Cell>{product.description}</Table.Cell>
                    <Table.Cell>{product.price}</Table.Cell>
                    <Table.Cell>
                      {/* Add action buttons or dropdowns here */}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
        </Card>
      </div>
    </section>
  );
};

export default MyComponent;

// import { Card, Button, Dropdown, Table, Pagination } from "flowbite-react";

// function MyComponent() {
//   return (
//     <section className="bg-gray-50 dark:bg-gray-900 p-3 sm:p-5">
//       <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
//         <Card>
//           <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
//             <div className="w-full md:w-1/2">
//               <form className="flex items-center">
//                 <div className="relative w-full">
//                   <SearchIcon className="absolute inset-y-0 left-0 h-5 w-5 text-gray-500 dark:text-gray-400" />
//                   <input
//                     type="text"
//                     id="simple-search"
//                     className="..."
//                     placeholder="Search"
//                     required
//                   />
//                 </div>
//               </form>
//             </div>
//             <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">
//               <Button>
//                 <PlusIcon className="h-3.5 w-3.5 mr-2" />
//                 Add product
//               </Button>
//               <Dropdown label="Actions">{/* Dropdown items */}</Dropdown>
//               <Dropdown label="Filter">{/* Dropdown items */}</Dropdown>
//             </div>
//           </div>
//           <div className="overflow-x-auto">
//             <Table>
//               <Table.Head>
//                 <Table.HeadCell>Product name</Table.HeadCell>
//                 <Table.HeadCell>Category</Table.HeadCell>
//                 <Table.HeadCell>Brand</Table.HeadCell>
//                 <Table.HeadCell>Description</Table.HeadCell>
//                 <Table.HeadCell>Price</Table.HeadCell>
//                 <Table.HeadCell>
//                   <span className="sr-only">Actions</span>
//                 </Table.HeadCell>
//               </Table.Head>
//               <Table.Body className="divide-y">
//                 <Table.Row>
//                   <Table.Cell>Apple iMac 27"</Table.Cell>
//                   <Table.Cell>PC</Table.Cell>
//                   <Table.Cell>Apple</Table.Cell>
//                   <Table.Cell>300</Table.Cell>
//                   <Table.Cell>$2999</Table.Cell>
//                   <Table.Cell>{/* Actions Dropdown or Buttons */}</Table.Cell>
//                 </Table.Row>
//                 {/* Repeat Table.Row for each product */}
//               </Table.Body>
//             </Table>
//           </div>
//           <Pagination>
//             <Pagination.Prev>Previous</Pagination.Prev>
//             <Pagination.Item>1</Pagination.Item>
//             <Pagination.Item>2</Pagination.Item>
//             <Pagination.Item active>3</Pagination.Item>
//             <Pagination.Ellipsis />
//             <Pagination.Item>100</Pagination.Item>
//             <Pagination.Next>Next</Pagination.Next>
//           </Pagination>
//         </Card>
//       </div>
//     </section>
//   );
// }

// export default MyComponent;
