import React from "react";
import styles from "./MessagePreview.module.css";
import { ReactComponent as BatteryIcon } from "./previewAssets/battery-icon.svg";
import { ReactComponent as WifiIcon } from "./previewAssets/wifi-icon.svg";
import { ReactComponent as SignalIcon } from "./previewAssets/antena-bars-icon.svg";
import { ReactComponent as FlashlightIcon } from "./previewAssets/flashlight-icon.svg";
import { ReactComponent as CameraIcon } from "./previewAssets/camera-icon.svg";
import Wallpaper from "./previewAssets/wallpaper.png";
import MessageApp from "./previewAssets/messageApp.png";

type MessagePreviewProps = {
  date: string;
  time: string;
  message: string;
  title: string;
  clientFirstName: string;
  clientLastName: string;
  staffName: string;
  serviceType: string;
  appointmentTime: string;
  link: string;
};

const MessagePreview: React.FC<MessagePreviewProps> = ({
  date,
  time,
  message,
  title,
  clientFirstName,
  clientLastName,
  staffName,
  serviceType,
  appointmentTime,
  link,
}) => {

  const values = {
    "%{clientFirstName}": clientFirstName,
    "%{clientLastName}": clientLastName,
    "%{staffName}": staffName,
    "%{serviceType}": serviceType,
    "%{appointmentTime}": appointmentTime,
    "%{link}": link,
  };
  
  // const predefinedValues = {
  //   "%{clientFirstName}": "Alex",
  //   "%{clientLastName}": "Doe",
  //   "%{staffName}": "Jane",
  //   "%{serviceType}": "Haircut",
  //   "%{appointmentTime}": "Monday, June 6, 11:00 AM",
  //   "%{link}": "www.example.com",
  //   // Add more mappings as needed
  // };

  const replacePlaceholders = (template: string, values: { [key: string]: string }): string => {
    let result = template;
    Object.keys(values).forEach(key => {
      result = result.replace(new RegExp(key, 'g'), values[key]);
    });
    return result;
  };

  const formattedMessage = replacePlaceholders(message, values);

  const messageLines = formattedMessage
    .split("\n")
    .map((line, index, array) => (
      <React.Fragment key={index}>
        {line}
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));

  return (
    <div className={styles.backgroundContainer}>
      <img src={Wallpaper} alt="Background"/>

      {/* Upper Container */}
      <div className={styles.upperRow}>
        <div className={styles.upperLeftContainer}>{time}</div>
        <div className={styles.upperMiddleContainer}></div>
        <div className={styles.upperRightContainer}>
          <SignalIcon />
          <WifiIcon />
          <BatteryIcon />
        </div>
      </div>

      {/* Date Container */}

      <div>
        <div className={styles.centerText}>{date}</div>
        <div className={styles.largeCenterText}>{time}</div>
      </div>

      {/* Notification Container */}
      <div className={styles.notificationContainer}>
        <div className={styles.notificationBox}>
          <div className={styles.titleRow}>
            <div className={styles.iconBox}>
              <img
                className={styles.iconImage}
                src={MessageApp}
                alt="MessageApp"
              />
            </div>
            <div className={styles.flexGrowBox}>
              <div className={styles.titleRow}>
                <div className={styles.timeText}>
                  <div className={styles.smallText}>34m ago</div>
                </div>
              </div>
              <div className={styles.titleText}>{title}</div>
              <div className={styles.messageText}>{messageLines}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Lower Container */}
      <div className={styles.lowerContainer}>
        <div className={styles.smallCircle}>
          <FlashlightIcon />
        </div>
        <div className={styles.smallCircle}>
          <CameraIcon />
        </div>
      </div>

      <div className={styles.horizontalBar}></div>
    </div>
  );
};

export default MessagePreview;
