// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideMenu_separator__ZpMt1 {
    opacity: 0.1;
    margin-top: 0.5px; /* Space below the logo */
    margin-bottom: 8px; /* Space above the first item */
    border-bottom: 3px solid #E5E7EB; /* Tailwind gray-200 color */
    width: 100%; /* Full width */
}

.SideMenu_bottomIcons__4tg17 {
    margin-top: auto; /* Pushes the icons to the bottom */
    margin-bottom: 32px; /* Space above the bottom of the page */
    display: flex;
    justify-content: center; /* Center the icons horizontally */
    gap: 10px; /* Adjust the gap between the icons as needed */
}
`, "",{"version":3,"sources":["webpack://./src/components/SideMenu/SideMenu.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB,EAAE,yBAAyB;IAC5C,kBAAkB,EAAE,+BAA+B;IACnD,gCAAgC,EAAE,4BAA4B;IAC9D,WAAW,EAAE,eAAe;AAChC;;AAEA;IACI,gBAAgB,EAAE,mCAAmC;IACrD,mBAAmB,EAAE,uCAAuC;IAC5D,aAAa;IACb,uBAAuB,EAAE,kCAAkC;IAC3D,SAAS,EAAE,+CAA+C;AAC9D","sourcesContent":[".separator {\n    opacity: 0.1;\n    margin-top: 0.5px; /* Space below the logo */\n    margin-bottom: 8px; /* Space above the first item */\n    border-bottom: 3px solid #E5E7EB; /* Tailwind gray-200 color */\n    width: 100%; /* Full width */\n}\n\n.bottomIcons {\n    margin-top: auto; /* Pushes the icons to the bottom */\n    margin-bottom: 32px; /* Space above the bottom of the page */\n    display: flex;\n    justify-content: center; /* Center the icons horizontally */\n    gap: 10px; /* Adjust the gap between the icons as needed */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `SideMenu_separator__ZpMt1`,
	"bottomIcons": `SideMenu_bottomIcons__4tg17`
};
export default ___CSS_LOADER_EXPORT___;
