import React, { FC } from "react";

import BookedAppointments from "../../components/RemindersManagement/BookedAppointments/BookedAppointments";
import RemindersAmount from "../../components/RemindersManagement/RemindersAmount/RemindersAmount";
import FilledGaps from "../../components/OverviewPage/FilledGaps/FilledGaps";
import RecommendationsTable from "../../components/RemindersManagement/RecommendationsTable/RecommendationsTable";

const RemindersManagementPage: FC = function () {
  return (
    <div className="mb-5">
      <div className="flex justify-between w-full gap-4 mb-4">
        <div className="flex w-1/6 mr-4">
          <BookedAppointments />
        </div>
        <div className="flex w-1/3">
          <RemindersAmount />
        </div>
        <div className="flex w-1/2">
          <FilledGaps />
        </div>
      </div>
      <div className="w-full gap-6">
      <RecommendationsTable />
      </div>
    </div>
  );
};

export default RemindersManagementPage;
