// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ProfilePicture.module.css */

.ProfilePicture_profileContainer__3pXCv {
    /* Container styles (e.g., alignment, size constraints) */
    width: 50px; /* Example size */
    height: 50px;
    border-radius: 50%; /* Circular container */
    overflow: hidden; /* Ensures the image is contained within the border radius */
}

.ProfilePicture_profileImage__74U0b {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    border-radius: 50%; /* Circular image */
}

.ProfilePicture_profileCircle__eU93R {
    width: 32px; /* Adjust size as needed */
    height: 32px;
    border-radius: 50%; /* Circular shape */
    background-color: #2C2B4A;
    color: white; /* White text */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px; /* Adjust font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/ProfilePicture/ProfilePicture.module.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,yDAAyD;IACzD,WAAW,EAAE,iBAAiB;IAC9B,YAAY;IACZ,kBAAkB,EAAE,uBAAuB;IAC3C,gBAAgB,EAAE,4DAA4D;AAClF;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAE,2CAA2C;IAC9D,kBAAkB,EAAE,mBAAmB;AAC3C;;AAEA;IACI,WAAW,EAAE,0BAA0B;IACvC,YAAY;IACZ,kBAAkB,EAAE,mBAAmB;IACvC,yBAA+C;IAC/C,YAAY,EAAE,eAAe;IAC7B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe,EAAE,+BAA+B;AACpD","sourcesContent":["/* ProfilePicture.module.css */\n\n.profileContainer {\n    /* Container styles (e.g., alignment, size constraints) */\n    width: 50px; /* Example size */\n    height: 50px;\n    border-radius: 50%; /* Circular container */\n    overflow: hidden; /* Ensures the image is contained within the border radius */\n}\n\n.profileImage {\n    width: 100%;\n    height: 100%;\n    object-fit: cover; /* Ensures the image covers the container */\n    border-radius: 50%; /* Circular image */\n}\n\n.profileCircle {\n    width: 32px; /* Adjust size as needed */\n    height: 32px;\n    border-radius: 50%; /* Circular shape */\n    background-color: theme('colors.dark_blue.700');\n    color: white; /* White text */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 12px; /* Adjust font size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileContainer": `ProfilePicture_profileContainer__3pXCv`,
	"profileImage": `ProfilePicture_profileImage__74U0b`,
	"profileCircle": `ProfilePicture_profileCircle__eU93R`
};
export default ___CSS_LOADER_EXPORT___;
