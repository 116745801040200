import React, { FC } from "react";
import { Progress } from "flowbite-react";
import { ReactComponent as IncreaseGraphIcon } from "../../../assets/increase-graph-icon.svg";
import styles from "./MessagesImpact.module.css";

const MessagesImpact: FC = () => {
  return (
    <div className={styles.dashboardGraph}>
      <div className={styles.mainStatistics}>60%</div>
      <div className={styles.subtitle}>Messages Impact</div>
      <div className="text-xs font-medium">
        Improvement in clientele appointment frequency
      </div>
      <div className={styles.progressBarWrapper}>
        <div>
          <Progress progress={60} color="green" size="sm" />
        </div>
        <div className="text-sm flex justify-between">
          <div>
            <IncreaseGraphIcon className="inline mr-2" />
            <span className="text-green-400 font-extrabold">+7</span> vs. Last
            month
          </div>
          <div className="font-extrabold">
            <span className="text-green-400">329</span>/548
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesImpact;
