import React, { FC } from "react";
import Chart from "react-apexcharts";
import styles from "./ActiveClients.module.css";
import colors from "../../../utils/colors";
import { ReactComponent as ClientIcon } from "../../../assets/client-icon.svg";
import { ReactComponent as TriangleIncreaseIcon } from "../../../assets/triangle-increase-icon.svg";
import { ReactComponent as TriangleDecreaseIcon } from "../../../assets/triangle-decrease-icon.svg";

const ActiveClients: FC = () => {
  const data = [31, 40, 28, 51, 42, 109, 100]; // Replace with actual data
  const latestStatistic = data[data.length - 1];
  const trend =
    data.length > 1
      ? (
          ((latestStatistic - data[data.length - 2]) / data[data.length - 2]) *
          100
        ).toFixed(2)
      : "0.00";
  const isTrendPositive = parseFloat(trend) >= 0;

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.statisticsContainer}>
          <div className={styles.statistics}>
            <span className={styles.mainStatistics}>{latestStatistic}</span>
            <ClientIcon className={styles.icon} />
          </div>
          <div className={styles.subtitle}>Active clients</div>
        </div>
        <div className={styles.trend}>
          {isTrendPositive ? (
            <TriangleIncreaseIcon className={styles.trendIcon} />
          ) : (
            <TriangleDecreaseIcon className={styles.trendIcon} />
          )}
          <span
            className={`${styles.trendNumber} ${styles.spaceRight}`}
            style={{ color: isTrendPositive ? "#59B36B" : "#DD3E3E" }}
          >
            {trend}%
          </span>
          <span className={styles.trendText}>vs. Last month</span>
        </div>
      </div>
      <div className={styles.graphContainer}>
        <Chart
          options={{
            chart: { type: "area", height: 350, zoom: { enabled: false } },
            dataLabels: { enabled: false },
            stroke: {
              show: false,
            },
            xaxis: {
              type: "category",
              categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
            },
            tooltip: { x: { format: "dd/MM/yy HH:mm" } },
            fill: {
                type: "gradient",
                gradient: {
                  shadeIntensity: 0.4,
                  inverseColors: false,
                  opacityFrom: 0.5,
                  opacityTo: 0,
                  stops: [0, 50, 95, 100],
                  colorStops: [
                    {
                      offset: 0,
                      color: colors.lightPurple700,
                      opacity: 1
                    },
                    {
                      offset: 100,
                      color: colors.lightPurple700,
                      opacity: 0
                    },
                  ]
                },
              },
          }}
          series={[{ name: "Active Clients", data }]}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default ActiveClients;
