// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PartnerDropdown.module.css */
.PartnerDropdown_dropdownButton__bsuLC {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  height: 2.5rem; /* Equivalent to h-10 */
  font-size: 16px; /* Similar to text-base */
  background-color: var(--dark-blue-500); /* Dark blue background */
  color: #2c2a4a;
  font-weight: 600;
  border-radius: 9999px; /* Similar to rounded-full */
  width: 100%; /* Full width */
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s; /* Transition for hover effect */

}

.PartnerDropdown_dropdownButton__bsuLC:hover {
  background-color: var(--dark-blue-600) !important; /* Darker blue for hover */
  color: #2c2a4a;
  font-weight: 600;
}

.PartnerDropdown_dropdownButton__bsuLC:focus {
    box-shadow: none; /* Reinforce removal of the box shadow */
  }

.PartnerDropdown_dropdownIcon__Ovl-v {
  margin-right: 8px; /* Space between icon and text */
  height: 24px; /* Similar to h-6 */
  width: 24px; /* Similar to w-6 */
  color: inherit; /* Icon color */
}
`, "",{"version":3,"sources":["webpack://./src/components/SideMenu/PartnerDropdown/PartnerDropdown.module.css"],"names":[],"mappings":"AAAA,+BAA+B;AAC/B;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B,EAAE,6BAA6B;EAC1D,cAAc,EAAE,uBAAuB;EACvC,eAAe,EAAE,yBAAyB;EAC1C,sCAAsC,EAAE,yBAAyB;EACjE,cAAc;EACd,gBAAgB;EAChB,qBAAqB,EAAE,4BAA4B;EACnD,WAAW,EAAE,eAAe;EAC5B,eAAe;EACf,6CAA6C,EAAE,gCAAgC;;AAEjF;;AAEA;EACE,iDAAiD,EAAE,0BAA0B;EAC7E,cAAc;EACd,gBAAgB;AAClB;;AAEA;IACI,gBAAgB,EAAE,wCAAwC;EAC5D;;AAEF;EACE,iBAAiB,EAAE,gCAAgC;EACnD,YAAY,EAAE,mBAAmB;EACjC,WAAW,EAAE,mBAAmB;EAChC,cAAc,EAAE,eAAe;AACjC","sourcesContent":["/* PartnerDropdown.module.css */\n.dropdownButton {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start; /* Align items to the start */\n  height: 2.5rem; /* Equivalent to h-10 */\n  font-size: 16px; /* Similar to text-base */\n  background-color: var(--dark-blue-500); /* Dark blue background */\n  color: #2c2a4a;\n  font-weight: 600;\n  border-radius: 9999px; /* Similar to rounded-full */\n  width: 100%; /* Full width */\n  cursor: pointer;\n  transition: background-color 0.2s, color 0.2s; /* Transition for hover effect */\n\n}\n\n.dropdownButton:hover {\n  background-color: var(--dark-blue-600) !important; /* Darker blue for hover */\n  color: #2c2a4a;\n  font-weight: 600;\n}\n\n.dropdownButton:focus {\n    box-shadow: none; /* Reinforce removal of the box shadow */\n  }\n\n.dropdownIcon {\n  margin-right: 8px; /* Space between icon and text */\n  height: 24px; /* Similar to h-6 */\n  width: 24px; /* Similar to w-6 */\n  color: inherit; /* Icon color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownButton": `PartnerDropdown_dropdownButton__bsuLC`,
	"dropdownIcon": `PartnerDropdown_dropdownIcon__Ovl-v`
};
export default ___CSS_LOADER_EXPORT___;
