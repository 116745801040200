import React from "react";
import {ReactComponent as NotificationsIcon} from "../../../assets/notifications-icon.svg";
import styles from './Notifications.module.css';


const Notifications: React.FC = () => {
    return (
        <div className={styles.notificationsContainer}>
            <NotificationsIcon className={styles.notificationsIcon} />
            <div className={styles.notificationCircle}>
                <div className={styles.notificationCount}>6</div>
            </div>
        </div>
    );
};
export default Notifications;