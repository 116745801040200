// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActiveClients_container__9yEPx {
    background-color: white;
    padding: 12px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.ActiveClients_header__QLsDw {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start; /* Align items to the top */
}

.ActiveClients_statisticsContainer__4L24\\+ {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.ActiveClients_statistics__2TP\\+- {
    display: flex;
    align-items: center;
}

.ActiveClients_mainStatistics__Gd5-m {
    color: #2C2B4A;
    font-size: 2.25rem;
    font-weight: bold;
}

.ActiveClients_icon__b7BNB {
    margin-left: 8px;
    width: 32px;
    height: 32px;
}

.ActiveClients_subtitle__K2rKh {
    color: #6F6E89;
    font-weight: bold;
}

.ActiveClients_trend__rYtDN {
    display: flex;
    align-items: center;
}

.ActiveClients_trendIcon__19ISS {
    margin-right: 0.25rem;
    width: 1rem;
    height: 1rem;
}

.ActiveClients_trendNumber__wqjJL {
    font-weight: bold;
    font-size: 14px;
    margin-right: 5px;
}

.ActiveClients_trendText__hro2B {
    font-size: 14px;
}

.ActiveClients_graphContainer__KcAfW {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 8px;
}

.ActiveClients_spaceRight__xanXn {
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/OverviewPage/ActiveClients/ActiveClients.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,uBAAuB,EAAE,2BAA2B;AACxD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".container {\n    background-color: white;\n    padding: 12px;\n    border-radius: 8px;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 20px;\n}\n\n.header {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    align-items: flex-start; /* Align items to the top */\n}\n\n.statisticsContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}\n\n.statistics {\n    display: flex;\n    align-items: center;\n}\n\n.mainStatistics {\n    color: #2C2B4A;\n    font-size: 2.25rem;\n    font-weight: bold;\n}\n\n.icon {\n    margin-left: 8px;\n    width: 32px;\n    height: 32px;\n}\n\n.subtitle {\n    color: #6F6E89;\n    font-weight: bold;\n}\n\n.trend {\n    display: flex;\n    align-items: center;\n}\n\n.trendIcon {\n    margin-right: 0.25rem;\n    width: 1rem;\n    height: 1rem;\n}\n\n.trendNumber {\n    font-weight: bold;\n    font-size: 14px;\n    margin-right: 5px;\n}\n\n.trendText {\n    font-size: 14px;\n}\n\n.graphContainer {\n    width: 100%;\n    height: auto;\n    background-color: #ffffff;\n    padding: 1rem;\n    border-radius: 8px;\n}\n\n.spaceRight {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ActiveClients_container__9yEPx`,
	"header": `ActiveClients_header__QLsDw`,
	"statisticsContainer": `ActiveClients_statisticsContainer__4L24+`,
	"statistics": `ActiveClients_statistics__2TP+-`,
	"mainStatistics": `ActiveClients_mainStatistics__Gd5-m`,
	"icon": `ActiveClients_icon__b7BNB`,
	"subtitle": `ActiveClients_subtitle__K2rKh`,
	"trend": `ActiveClients_trend__rYtDN`,
	"trendIcon": `ActiveClients_trendIcon__19ISS`,
	"trendNumber": `ActiveClients_trendNumber__wqjJL`,
	"trendText": `ActiveClients_trendText__hro2B`,
	"graphContainer": `ActiveClients_graphContainer__KcAfW`,
	"spaceRight": `ActiveClients_spaceRight__xanXn`
};
export default ___CSS_LOADER_EXPORT___;
