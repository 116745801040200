import React, { useState, useRef, useEffect } from 'react';
import { Card, Textarea, Button, Badge } from 'flowbite-react';
import MessagePreview from '../MessagePreview/MessagePreview';
import {ReactComponent as PreviewIcon} from "../../../assets/preview-icon.svg";
import {ReactComponent as QuestionMarkIcon} from "../../../assets/question-mark-icon.svg";



type CardTemplateProps = {
    title: string;
    description: string;
    defaultMessage: string;
};

const formatMessage = (msg: string) => {
    return msg.replace(/\\n/g, '\n');
  };

const CardTemplate: React.FC<CardTemplateProps> = ({ title, description, defaultMessage }) => {
    const maxTextLength = 160;
    
    const [isActive, setIsActive] = useState(true);
    const [message, setMessage] = useState<string>(formatMessage(defaultMessage));
    const [showMessagePreview, setShowMessagePreview] = useState(false);
    const previewRef = useRef<HTMLDivElement>(null);

    
    const handleToggleActive = () => {
        setIsActive(!isActive);
    };

    const handleDragStart = (e: React.DragEvent<HTMLElement>, badgeText: string) => {
        e.dataTransfer.setData("text/plain", badgeText);
    };

    const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
        e.preventDefault();
        const badgeText = e.dataTransfer.getData("text/plain");
        setMessage(prev => prev.length + badgeText.length <= maxTextLength ? prev + badgeText : prev);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newText = e.target.value;
        if (newText.length <= maxTextLength) {
            setMessage(newText);
        }
    };

    const handlePreview = () => {
        setShowMessagePreview(true);
    };
    
    const handleClickOutside = (event: MouseEvent) => {
        if (previewRef.current && event.target instanceof HTMLElement && event.target.id === 'modalOverlay') {
            setShowMessagePreview(false);
        }
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    

    return (
        <Card className="mt-8">
            <div className="flex justify-between items-start">
                 <div className='flex flex-row gap-2'>
                 <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {title}
                </h5>
                <QuestionMarkIcon className="w-3 h-3" />
                </div>
                <div className="flex items-center gap-2">
                <Button color="none" className="focus:ring-0 focus:outline-none" onClick={handlePreview}>
                        <PreviewIcon className="w-6 h-6" />
                </Button>
                <Button color={isActive ? "success" : "gray"} className="focus:ring-0 focus:outline-none" onClick={handleToggleActive}>
                    {isActive ? "ACTIVE" : "INACTIVE"}
                </Button>
                    </div>
            </div>
            <p className="font-normal text-gray-700 dark:text-gray-400" style={{ maxWidth: '75%' }}>
                {description}
            </p>
            <div className="flex flex-row">
                <div className="flex-grow mr-4" style={{ maxWidth: '75%' }}>
                    <Textarea
                        id="messageInput"
                        placeholder="Type your message here..."
                        rows={4}
                        value={message}
                        onChange={handleMessageChange}
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                    />
                    <div className={`text-sm ${message.length > maxTextLength ? 'text-red-500' : 'text-gray-600'}`} style={{ textAlign: 'right' }}>
                        {message.length}/ {maxTextLength}
                    </div>
                </div>
                <div className="flex flex-wrap gap-2 mt-2 h-fit" style={{ maxWidth: '25%'}}>
                    <Badge color="blue" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{clientFirstName} ")}>Client First Name</Badge>
                    <Badge color="blue" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{clientLastName} ")}>Client Last Name</Badge>
                    <Badge color="green" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{staffName} ")}>Staff Name</Badge>
                    <Badge color="red" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{serviceType} ")}>Service Type</Badge>
                    <Badge color="red" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{appointmentTime} ")}>Appointment Time</Badge>
                    <Badge color="gray" className="w-auto mr-1 mb-1" draggable={true} onDragStart={(e) => handleDragStart(e, "%{link} ")}>Link</Badge>
                </div>
            </div>
            {showMessagePreview && (
    <div 
    ref={previewRef} 
    className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50"
    id="modalOverlay" // Add an ID for identification
>
<MessagePreview
  date="Monday, June 6"
  time="09:41"
  message={message}
  title="Your Reminder Title"
  clientFirstName="Alex"
  clientLastName="Doe"
  staffName="Jane"
  serviceType="Haircut"
  appointmentTime="Monday, June 6, 11:00 AM"
  link="www.example.com"
/>
</div>
            )}
        </Card>
    );
};

export default CardTemplate;


