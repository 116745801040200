import type { FC } from "react";

const BusinessNotSelectedPage: FC = function () {
  return (
    <section>
      <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl font-extrabold tracking-tight text-primary-600 dark:text-primary-500 lg:text-7xl">
          Let's Get Started!
          </h1>
          <p className="mb-4 text-3xl font-bold tracking-tight text-gray-900 dark:text-white md:text-4xl">
          Awaiting Your Selection
          </p>
          <p className="mb-4 text-lg text-gray-500 dark:text-gray-400">
            Please select at least one business from the top bar to display the data.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BusinessNotSelectedPage;
