import React, { FC } from 'react';
import ClientsDistributionChart from './ClientsDistributionChart/ClientsDistributionChart';
import ClientsDistributionTable from './ClientsDistributionTable/ClientsDistributionTable';
import { ReactComponent as ClientsDistributionIcon } from "../../assets/clients-distribution-icon.svg";
import styles from './ClientsDistribution.module.css';
import colors from '../../utils/colors';

interface ClientSegment {
    name: string;
    amount: number;
    trend: string;
    color: string;
  }

const ClientsDistribution: FC = () => {
    const clientSegments : ClientSegment[] = [
        { name: 'Active', amount: 548, color: colors.clientSegmentGreen, trend: '+12%'},
        { name: 'Inconsistent', amount: 177, color: colors.clientSegmentYellow, trend: '-10%' },
        { name: 'Risk', amount: 37, color: colors.clientSegmentOrange, trend: '-8%' },
        { name: 'Left', amount: 40, color: colors.clientSegmentRed, trend: '-2%' },
        { name: 'First Time', amount: 134, color: colors.clientSegmentPurple, trend: '+4%' },
        { name: 'Lost', amount: 712, color: colors.clientSegmentBlack, trend: '+9%' }
      ];


      return (
        <div className={styles.clientsDistributionContainer}>
          <div>
            <ClientsDistributionIcon className="inline mx-2" />
            <span className={styles.dashboardGraphSubtitle} style={{ color: colors.darkBlue600 }}>Clients Distribution</span>
          </div>
          <ClientsDistributionChart clientSegments={clientSegments} />
          <ClientsDistributionTable clientSegments={clientSegments} />
        </div>
      );
    }
export default ClientsDistribution;
