import React, { useEffect, useState, FC } from "react";
import { Dropdown, Checkbox } from 'flowbite-react';
import styles from './BusinessesDropdown.module.css';
import { useSelection } from '../../../context/SelectionContext';
import { Business } from '../../../types';



const businessData: Record<string, Business[]> = {
  'point': [
    { id: 'point1', name: 'Sheket Beauty Studio, TLV' },
    { id: 'point2', name: 'Tiferet Elegance, HFA' },
    { id: 'point3', name: 'Nurit Aesthetics, TLV' },
  ],
  'vcita': [
    { id: 'vcita1', name: 'Radiant Vogue Studio, NYC' },
    { id: 'vcita2', name: 'Sunset Glamour, LAX' },
    { id: 'vcita3', name: 'Starlit Elegance, CHI' },
  ],
  // Add more partners and their businesses here
};

const BusinessesDropdown: FC = () => {
  const { selectedPartner, selectedBusinesses, setSelectedBusinesses } = useSelection();
  const [availableBusinesses, setAvailableBusinesses] = useState<Business[]>([]);
  
  useEffect(() => {
    // Reset businesses when the partner changes
    setSelectedBusinesses([]);

    // Set available businesses based on the selected partner
    if (selectedPartner) {
      setAvailableBusinesses(businessData[selectedPartner.id] || []);
    } else {
      setAvailableBusinesses([]);
    }
  }, [selectedPartner, setSelectedBusinesses]);

  const isBusinessSelected = (id: string) => {
    return selectedBusinesses.some(business => business.id === id);
  };

  const handleCheckboxChange = (id: string) => {
    const isSelected = isBusinessSelected(id);
    const newSelected = isSelected
      ? selectedBusinesses.filter(business => business.id !== id)
      : [...selectedBusinesses, availableBusinesses.find(business => business.id === id)!];

    setSelectedBusinesses(newSelected);
  };

  const selectedLabels = selectedBusinesses.map(business => business.name);

  let label = "Select business"; // Default label
  if (selectedLabels.length === 1) {
    label = selectedLabels[0]; // Single selection label
  } else if (selectedLabels.length > 1) {
    label = `${selectedLabels.length} businesses`; // Multiple selection label
  }

  
  
  const customDropdownTheme = {
    floating: {
      target: styles.dropdownButton,
      content: styles.dropdownContent
    }
  };

  return (
    <Dropdown theme={customDropdownTheme} inline={true} label={label} disabled={!selectedPartner} dismissOnClick={false}>
      {availableBusinesses.map((business) => (
        <Dropdown.Item key={business.id} className={styles.dropdownItem}>
          <Checkbox 
            checked={isBusinessSelected(business.id)}
            onChange={() => handleCheckboxChange(business.id)}
            className={styles.checkbox}
          />
          {business.name}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

export default BusinessesDropdown;
