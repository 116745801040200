import React, { useEffect, useState, FC } from 'react';
import { useSelection } from '../../context/SelectionContext';
import { fetchDataForPartnerAndBusinesses } from '../../services/dataService';
import BusinessNotSelected from '../errors/business-not-selected'; // Adjust this import to the correct path

import RevenueGained from "../../components/OverviewPage/RevenueGained/RevenueGained";
import ClientsDistribution from '../../components/ClientsDistribution/ClientsDistribution';
import MessagesImpact from "../../components/OverviewPage/MessagesImpact/MessagesImpact";
import FilledGaps from "../../components/OverviewPage/FilledGaps/FilledGaps";
import ActiveClients from "../../components/OverviewPage/ActiveClients/ActiveClients";

import { ReactComponent as CalendarIcon } from "../../assets/calendar-icon.svg";

const OverviewPage: FC = function () {
  const { selectedPartner, selectedBusinesses } = useSelection();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (selectedPartner && selectedBusinesses.length > 0) {
      fetchDataForPartnerAndBusinesses(selectedPartner, selectedBusinesses)
        .then(fetchedData => {
          setData(fetchedData);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      setData(null);
    }
  }, [selectedPartner, selectedBusinesses]);

  // Render the BusinessNotSelected or specific component when no business is selected
  if (!selectedPartner || selectedBusinesses.length === 0) {
    return <BusinessNotSelected />;
  }

  // Render the usual dashboard content when a business is selected
  return (
    <div className="mb-5">
      <div className="flex justify-between w-full gap-6">
        <div className="flex w-1/6">
          <RevenueGained data={data}/>
        </div>
        <div className="flex w-1/3">
          <MessagesImpact />
        </div>
        <div className="flex w-1/2">
          <FilledGaps />
          {/* <FilledGapsDashboardGraph/> */}
        </div>
      </div>
      <div className="flex justify-between w-full gap-6">
        <ClientsDistribution />
        <div className="flex flex-col justify-between w-1/2">
          <ActiveClients />
          <div className="mt-5 bg-white justify-between p-3 rounded-2xl">
            <div className="flex items-center">
              <CalendarIcon className="inline-block mx-1.5" />
              <div className="dashboard-graph-subtitle">RECENT BOOKING</div>
            </div>
            <div>
              <table className="w-full text-left m-2 recent-booking-table">
                <tr>
                  <th>Date</th>
                  <th>Price</th>
                  <th>Service</th>
                </tr>
                <tr>
                  <td>15 February 2023</td>
                  <td>40$</td>
                  <td>Haircut + Beard</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPage;
