// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClientsDistribution_clientsDistributionContainer__EtvHW {
    margin-top: 1.25rem;
    background-color: white;
    justify-content: space-between;
    padding: 0.75rem;
    width: calc(50% + 24px);
    border-radius: 0.5rem;
}

.ClientsDistribution_dashboardGraphSubtitle__vMWU7 {
    font-size: 18px;
    font-weight: 700;
}

.ClientsDistribution_clientsDistributionTable__PFafg {
    box-shadow: 0 0.919525px 2.75858px rgba(0, 0, 0, 0.1), 0 0.919525px 1.83905px -0.919525px rgba(0, 0, 0, 0.1);
    border-radius: 7.3562px;
}`, "",{"version":3,"sources":["webpack://./src/components/ClientsDistribution/ClientsDistribution.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,4GAA4G;IAC5G,uBAAuB;AAC3B","sourcesContent":[".clientsDistributionContainer {\n    margin-top: 1.25rem;\n    background-color: white;\n    justify-content: space-between;\n    padding: 0.75rem;\n    width: calc(50% + 24px);\n    border-radius: 0.5rem;\n}\n\n.dashboardGraphSubtitle {\n    font-size: 18px;\n    font-weight: 700;\n}\n\n.clientsDistributionTable {\n    box-shadow: 0 0.919525px 2.75858px rgba(0, 0, 0, 0.1), 0 0.919525px 1.83905px -0.919525px rgba(0, 0, 0, 0.1);\n    border-radius: 7.3562px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clientsDistributionContainer": `ClientsDistribution_clientsDistributionContainer__EtvHW`,
	"dashboardGraphSubtitle": `ClientsDistribution_dashboardGraphSubtitle__vMWU7`,
	"clientsDistributionTable": `ClientsDistribution_clientsDistributionTable__PFafg`
};
export default ___CSS_LOADER_EXPORT___;
