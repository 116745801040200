import React, { FC, useState, useMemo } from "react";
import { Table, Pagination, Badge, Checkbox } from "flowbite-react";

// import MessagePreview from "../../RemindersEditor/MessagePreview/MessagePreview";

import styles from "./RecommendationsTable.module.css";
// import SortIcon from "../../../assets/arrows-sort-icon.svg";
import { ReactComponent as SendIcon } from "../../../assets/send-icon.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/preview-icon.svg";
import ArchiveButton from "./ArchiveButton";

interface DataItem {
  name: string;
  reminderTime: string;
  messageType: string;
  serviceProvider: string;
  lastEngagement: string;
  idealBooking: string;
  status: string;
  isArchived: boolean;
}

const sampleData: DataItem[] = [
  {
    name: "Julianne Murphy",
    reminderTime: "Oct 23, 2023, 19:00",
    messageType: "Filling gap",
    serviceProvider: "Emily Richardson",
    lastEngagement: "Mar 03, 2023",
    idealBooking: "Nov 01, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Keisha Brooks",
    reminderTime: "Oct 23, 2023, 19:00",
    messageType: "Filling gap",
    serviceProvider: "Emily Richardson",
    lastEngagement: "Apr 11, 2023",
    idealBooking: "Nov 01, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Ivan Rodriguez",
    reminderTime: "Oct 23, 2023, 19:00",
    messageType: "Return inactive client",
    serviceProvider: "Marcus Washington",
    lastEngagement: "Aug 23, 2022",
    idealBooking: "Feb 15, 2023",
    status: "Clicked",
    isArchived: false,
  },
  {
    name: "Fiona Cheng",
    reminderTime: "Oct 23, 2023, 18:37",
    messageType: "Return inactive client",
    serviceProvider: "Aisha Patel",
    lastEngagement: "Apr 05, 2022",
    idealBooking: "Mar 22, 2023",
    status: "Clicked",
    isArchived: false,
  },
  {
    name: "Amir Khan",
    reminderTime: "Oct 23, 2023, 18:35",
    messageType: "Scheduling optimization",
    serviceProvider: "Naomi O'Connor",
    lastEngagement: "Jul 17, 2023",
    idealBooking: "Nov 05, 2023",
    status: "Recommended",
    isArchived: false,
  },
  {
    name: "Zoe Bennett",
    reminderTime: "Oct 23, 2023, 18:30",
    messageType: "Return lost client",
    serviceProvider: "Aisha Patel",
    lastEngagement: "Jan 30, 2022",
    idealBooking: "Jun 10, 2022",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Carlos Rivera",
    reminderTime: "Oct 23, 2023, 18:22",
    messageType: "Return lost client",
    serviceProvider: "Marcus Washington",
    lastEngagement: "Aug 15, 2022",
    idealBooking: "Feb 24, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Hannah Kim",
    reminderTime: "Oct 23, 2023, 18:20",
    messageType: "Scheduling optimization",
    serviceProvider: "Naomi O'Connor",
    lastEngagement: "Aug 03, 2023",
    idealBooking: "Nov 09, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Tristan Dupont",
    reminderTime: "Oct 23, 2023, 18:19",
    messageType: "Scheduling optimization",
    serviceProvider: "Naomi O'Connor",
    lastEngagement: "Sep 08, 2023",
    idealBooking: "Nov 09, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Layla Shah",
    reminderTime: "Oct 23, 2023, 18:15",
    messageType: "Filling gap",
    serviceProvider: "Aisha Patel",
    lastEngagement: "Apr 22, 2023",
    idealBooking: "Nov 01, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Elena Gilbert",
    reminderTime: "Nov 15, 2023, 10:30",
    messageType: "Filling gap",
    serviceProvider: "Emily Richardson",
    lastEngagement: "Sep 21, 2023",
    idealBooking: "Dec 05, 2023",
    status: "Viewed",
    isArchived: false,
  },
  {
    name: "Damon Salvatore",
    reminderTime: "Nov 18, 2023, 14:00",
    messageType: "Return inactive client",
    serviceProvider: "Marcus Washington",
    lastEngagement: "Oct 10, 2023",
    idealBooking: "Dec 10, 2023",
    status: "Sent",
    isArchived: false,
  },
  {
    name: "Caroline Forbes",
    reminderTime: "Nov 20, 2023, 16:45",
    messageType: "Scheduling optimization",
    serviceProvider: "Aisha Patel",
    lastEngagement: "Oct 03, 2023",
    idealBooking: "Dec 15, 2023",
    status: "Clicked",
    isArchived: false,
  },
  {
    name: "Stefan Salvatore",
    reminderTime: "Nov 22, 2023, 09:20",
    messageType: "Scheduling optimizer",
    serviceProvider: "Naomi O'Connor",
    lastEngagement: "Oct 12, 2023",
    idealBooking: "Dec 20, 2023",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Bonnie Bennett",
    reminderTime: "Nov 25, 2023, 13:00",
    messageType: "Filling gap",
    serviceProvider: "Emily Richardson",
    lastEngagement: "Oct 22, 2023",
    idealBooking: "Dec 25, 2023",
    status: "Recommended",
    isArchived: false,
  },
  {
    name: "Tyler Lockwood",
    reminderTime: "Nov 28, 2023, 15:30",
    messageType: "Filling gap",
    serviceProvider: "Marcus Washington",
    lastEngagement: "Nov 01, 2023",
    idealBooking: "Jan 05, 2024",
    status: "Viewed",
    isArchived: false,
  },
  {
    name: "Alaric Saltzman",
    reminderTime: "Dec 01, 2023, 11:00",
    messageType: "Filling gap",
    serviceProvider: "Aisha Patel",
    lastEngagement: "Nov 05, 2023",
    idealBooking: "Jan 10, 2024",
    status: "Booked",
    isArchived: false,
  },
  {
    name: "Matt Donovan",
    reminderTime: "Dec 05, 2023, 17:15",
    messageType: "Filling gap",
    serviceProvider: "Naomi O'Connor",
    lastEngagement: "Nov 15, 2023",
    idealBooking: "Jan 15, 2024",
    status: "Clicked",
    isArchived: false,
  },
  {
    name: "Klaus Mikaelson",
    reminderTime: "Dec 10, 2023, 12:30",
    messageType: "Return inactive client",
    serviceProvider: "Emily Richardson",
    lastEngagement: "Nov 20, 2023",
    idealBooking: "Jan 20, 2024",
    status: "Sent",
    isArchived: false,
  },
  {
    name: "Rebekah Mikaelson",
    reminderTime: "Dec 15, 2023, 10:00",
    messageType: "Scheduling optimization",
    serviceProvider: "Marcus Washington",
    lastEngagement: "Nov 25, 2023",
    idealBooking: "Jan 25, 2024",
    status: "Recommended",
    isArchived: false,
  },
  // Add more entries as needed
];

const renderStatusBadge = (item: DataItem) => {
  let color;
  let statusText = item.status;

  if (item.isArchived) {
    color = "red";
    statusText = "ARCHIVED";
  } else {
    switch (item.status.toLowerCase()) {
      case "booked":
        color = "green";
        break;
      case "clicked":
        color = "blue";
        break;
      case "viewed":
        color = "yellow";
        break;
      case "recommended":
        color = "purple";
        break;
      case "sent":
        color = "indigo";
        break;
      default:
        color = "gray";
    }
  }

  return (
      <Badge color={color} className={styles.badgeCustom}>
        {statusText}
      </Badge>
  );
};

const RecommendationsTable: FC = () => {
  const [data, setData] = useState(sampleData);
  const [showArchived, setShowArchived] = useState(false);

  // Set the initial sortConfig state to sort by 'reminderTime' in ascending order
  const [sortConfig, setSortConfig] = useState<{
    key: keyof DataItem;
    direction: "ascending" | "descending";
  }>({
    key: "reminderTime",
    direction: "ascending",
  });

  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust as needed

  // Filtering logic
  const filteredData = data.filter((item) => {
    const matchesName = item.name.toLowerCase().includes(filter.toLowerCase());
    const isArchived = showArchived ? true : !item.isArchived;
    return matchesName && isArchived;
  });

  // Sorting logic

  const getSortedData = useMemo(() => {
    const sortableItems = [...filteredData]; // Create a copy to avoid mutating the original array
    if (sortConfig) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredData, sortConfig]);

  // Function to handle sorting when a column header is clicked
  const handleSort = (key: keyof DataItem) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig && sortConfig.key === key) {
      direction =
          sortConfig.direction === "ascending" ? "descending" : "ascending";
    }
    setSortConfig({ key, direction });
  };

  // State to track hover
  const [hoveredColumn, setHoveredColumn] = useState<string | null>(null);

  // Calculate the total number of entries (not just the ones on the current page)
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const totalFilteredEntries = filteredData.length; // Total number of entries in the filtered dataset

  // Pagination logic
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Calculate the range of entries for display
  const startEntry = firstItemIndex + 1;
  const endEntry = Math.min(lastItemIndex, totalFilteredEntries);
  const entriesText = (
      <span>
      Showing{" "}
        <span className="font-bold">
        {startEntry}-{endEntry}
      </span>{" "}
        of <span className="font-bold">{totalFilteredEntries}</span> entries
    </span>
  );

  // Icons Event Handlers
  const handleSendReminder = (item: DataItem) => {
    // Logic to handle send reminder action
    console.log("Sending reminder for:", item.name);
  };

  const handlePreviewReminder = (item: DataItem) => {
    // Implementation
    console.log(`Previewing reminder for: ${item.name}`);
  };
  //Handle archive
  const handleArchive = (itemName: string) => {
    setData((prevData) =>
        prevData.map((item) =>
            item.name === itemName
                ? { ...item, isArchived: !item.isArchived }
                : item
        )
    );
  };

  const toggleArchivedView = () => {
    setShowArchived(!showArchived);
  };

  return (
      <div className={styles.tableContainer}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="mb-2">
          <span className="text-xl font-semibold">
            REMINDERS RECOMMENDATIONS
          </span>
          </h2>
          <input
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filter by name"
              className={styles.search}
          />
        </div>

        <Table hoverable={true}>
          <Table.Head className={styles.headRow}>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("name")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("name")}
                className={`${styles.headerCell} ${hoveredColumn === "name" }`}
            >
              Name
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("reminderTime")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("reminderTime")}
                className={styles.headerCell}
            >
              Reminder Time
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("messageType")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("messageType")}
                className={styles.headerCell}
            >
              Message Type
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("serviceProvider")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("serviceProvider")}
                className={styles.headerCell}
            >
              Service Provider
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("lastEngagement")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("lastEngagement")}
                className={styles.headerCell}
            >
              Last Engagement
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("idealBooking")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("idealBooking")}
                className={styles.headerCell}
            >
              Ideal Booking
            </Table.HeadCell>
            <Table.HeadCell
                onMouseEnter={() => setHoveredColumn("status")}
                onMouseLeave={() => setHoveredColumn(null)}
                onClick={() => handleSort("status")}
                className={styles.headerCell}
            >
              Status
            </Table.HeadCell>
            <Table.HeadCell className={styles.headerCell}>Action</Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {getSortedData
                .slice(firstItemIndex, lastItemIndex)
                .map((item, index) => (
                    <Table.Row key={index} className={`group ${item.isArchived ? styles.archivedRow : ""}`}>
                      {" "}
                      <Table.Cell className={styles.tableCell}>
                        {item.name}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {item.reminderTime}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {item.messageType}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {item.serviceProvider}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {item.lastEngagement}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {item.idealBooking}
                      </Table.Cell>
                      <Table.Cell className={styles.tableCell}>
                        {renderStatusBadge(item)}
                      </Table.Cell>
                      <Table.Cell className={`${styles.actionCell} ${styles.tableCell}`}>
                        <div className={`${styles.actionIcons} group-hover:block`}>
                          {/* Always display the action icons if not archived */}
                          {!item.isArchived ? (
                              <>
                                <button onClick={() => handleSendReminder(item)} className="mr-0.5">
                                  <SendIcon />
                                </button>
                                <button onClick={() => handlePreviewReminder(item)} className={styles.previewIcon}>
                                  <PreviewIcon/>
                                </button>
                              </>
                          ) : null}
                          {/* ArchiveButton is always displayed but changes state based on isArchived */}
                          <ArchiveButton
                              isArchived={item.isArchived}
                              onToggleArchive={() => handleArchive(item.name)}
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                ))}
          </Table.Body>
        </Table>
        <div className="flex justify-between items-center mt-2">
          <div className="flex items-center text-sm text-gray-500">
            {entriesText}
            <div className="ml-4 flex items-center">
              <Checkbox
                  id="archiveCheckbox"
                  checked={showArchived}
                  onChange={toggleArchivedView}
                  className="mr-2"
              />
              <label htmlFor="archiveCheckbox">Show Archived</label>
            </div>
          </div>
          <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              previousLabel=""
              nextLabel=""
              showIcons
              className={styles.smallPagination}
          />
        </div>
      </div>
  );
};

export default RecommendationsTable;