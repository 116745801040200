const colors = {
    deepPurple300: '#D5C9DD',
    deepPurple400: '#AA93B6',
    deepPurple500: '#805D93',
    deepPurple600: '#612675',
    deepPurple700: '#3A1145',
    deepPurple800: '#310E3A',
    deepPurple900: '#1D0822',

    lightPurple300: '#E3D9E9',
    lightPurple400: '#AA93B6',
    lightPurple500: '#A787B9',
    lightPurple600: '#8C55A8',
    lightPurple700: '#8C61A4',

    darkBlue300: '#CBC9D1',
    darkBlue400: '#9594A4',
    darkBlue500: '#605E76',
    darkBlue600: '#2C2A4A',
    darkBlue700: '#2C2B4A',

    mossGreen200: '#31C48D',
    mossGreen300: '#CFD9C9',
    mossGreen400: '#A2BA97',
    mossGreen500: '#8BB278',
    mossGreen600: '#699B51',

    clientSegmentBlack: '#273031',
    clientSegmentPurple: '#8C55A8',
    clientSegmentRed: '#DD3E3E',
    clientSegmentOrange: '#FF9213',
    clientSegmentYellow: '#F2C523',
    clientSegmentGreen: '#59B36B',
};

export default colors;
