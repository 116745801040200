// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TopMenuContainer_topNav__JAFB\\+ {
 display: flex;
 align-items: center /* Align items vertically */
}

.TopMenuContainer_viewingDataText__1IWg9 {
    margin-right: 1rem; /* Adjust spacing as needed */
}

.TopMenuContainer_topNavSearchAndIcons__3jRq3 {
 margin-left: auto; /* Aligns the child elements to the right */ display: flex; align-items: center /* Align items vertically */
}

.TopMenuContainer_topNavSearchAndIcons__3jRq3 > *:not(:last-child) {
 margin-left: 1.25rem;
 margin-right: 1.25rem /* Apply left margin to all child elements except the first */
}

`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/TopMenuContainer/TopMenuContainer.module.css"],"names":[],"mappings":";AAEI;CAAA,aAAW;CACX,kBAAmB,EAAE,2BAA2B;AADrC;;AAIf;IACI,kBAAkB,EAAE,6BAA6B;AACrD;;AAGI;CAAA,iBAAc,EAAE,2CAA2C,EAC3D,aAAW,EACX,kBAAmB,EAAE,2BAA2B;AAFlC;;AAMd;CAAA,oBAAW;CAAX,oBAAW,EAAE,6DAA6D;AAA/D","sourcesContent":["\n.topNav {\n    @apply flex;\n    @apply items-center; /* Align items vertically */\n}\n\n.viewingDataText {\n    margin-right: 1rem; /* Adjust spacing as needed */\n}\n\n.topNavSearchAndIcons {\n    @apply ml-auto; /* Aligns the child elements to the right */\n    @apply flex;\n    @apply items-center; /* Align items vertically */\n}\n\n.topNavSearchAndIcons > *:not(:last-child) {\n    @apply mx-5; /* Apply left margin to all child elements except the first */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topNav": `TopMenuContainer_topNav__JAFB+`,
	"viewingDataText": `TopMenuContainer_viewingDataText__1IWg9`,
	"topNavSearchAndIcons": `TopMenuContainer_topNavSearchAndIcons__3jRq3`
};
export default ___CSS_LOADER_EXPORT___;
