// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MessagesImpact.module.css */

.MessagesImpact_dashboardGraph__88Yd5 {
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    min-width: -moz-fit-content;
    min-width: fit-content;
    background-color: white;
    padding: 1rem;
    white-space: nowrap;
    flex: 8 1;

}

.MessagesImpact_mainStatistics__oWAp1 {
    font-size: 2.25rem;
    font-weight: bold;
    color: #2C2B4A;
}

.MessagesImpact_subtitle__QGdRg {
    font-weight: bold;
    color: #6F6E89;
    margin-top: 0.5rem;
}

.MessagesImpact_progressBarWrapper__KC6Kl {
    margin-top: auto; /* This pushes the wrapper to the bottom */
    display: flex;
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/OverviewPage/MessagesImpact/MessagesImpact.module.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,2BAAsB;IAAtB,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,SAAO;;AAEX;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB,EAAE,0CAA0C;IAC5D,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["/* MessagesImpact.module.css */\n\n.dashboardGraph {\n    display: flex;\n    border-radius: 8px;\n    flex-direction: column;\n    min-width: fit-content;\n    background-color: white;\n    padding: 1rem;\n    white-space: nowrap;\n    flex: 8;\n\n}\n\n.mainStatistics {\n    font-size: 2.25rem;\n    font-weight: bold;\n    color: #2C2B4A;\n}\n\n.subtitle {\n    font-weight: bold;\n    color: #6F6E89;\n    margin-top: 0.5rem;\n}\n\n.progressBarWrapper {\n    margin-top: auto; /* This pushes the wrapper to the bottom */\n    display: flex;\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardGraph": `MessagesImpact_dashboardGraph__88Yd5`,
	"mainStatistics": `MessagesImpact_mainStatistics__oWAp1`,
	"subtitle": `MessagesImpact_subtitle__QGdRg`,
	"progressBarWrapper": `MessagesImpact_progressBarWrapper__KC6Kl`
};
export default ___CSS_LOADER_EXPORT___;
