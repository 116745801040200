import React from "react";
import { Tabs } from "flowbite-react";
import styles from './FeatureTabs.module.css';
import CardTemplate from "../CardTemplate/CardTemplate";

const FeatureTabs: React.FC = () => {
  return (
      // eslint-disable-next-line react/style-prop-object
    <Tabs.Group aria-label="Tabs with icons" style="underline">
      <Tabs.Item active title="SCHEDULING OPTIMIZATION">
      This is{" "}
        <span className={styles.tabTitle}>
          Scheduling optimization's associated content
        </span>
        . Clicking another tab will toggle the visibility of this one for the
        next. The tab JavaScript swaps classes to control the content visibility
        and styling.
        <CardTemplate 
          title="Scheduling Optimization"
          description="Add a personal message to encourage your clients to visit more often. This message will be sent automatically to each client, depending on their booking patterns and preferences."
          defaultMessage="Hey %{clientFirstName}, it's time to schedule your next %{serviceType}. \nWe are looking forward to seeing you soon! \n%{link}"
        />
      </Tabs.Item>
      <Tabs.Item title="FILLING GAPS">
      This is{" "}
        <span className={styles.tabTitle}>
          Filling gaps tab's associated content
        </span>
        . Clicking another tab will toggle the visibility of this one for the
        next. The tab JavaScript swaps classes to control the content visibility
        and styling.
        <CardTemplate 
          title="Last minute gaps"
          description="Add a personal message to encourage your clients to fill in last minute gaps. This message will be sent automatically after last minute cancelation to clients who are most likely to fill this slot."
          defaultMessage="Hey %{clientFirstName}! \nA new slot has just opened up at %{appointmentTime} for %{staffName}. \nDon't miss out this opportunity and secure your booking. \n%{link}"
        />
        <CardTemplate 
          title="Next day gaps"
          description="Inform your clients about available slots for the next day.
          This message will be sent to clients that are most likely to fill these gaps, at the end of your business day."
          defaultMessage="Hey %{clientFirstName}, just a quick note to inform you that %{staffName} has available slots for tommorow. \nBook at your earliest convenience. \n%{link}"
        />
      </Tabs.Item>
      <Tabs.Item title="RETENTION">
      This is{" "}
        <span className={styles.tabTitle}>
          Retention tab's associated content
        </span>
        . Clicking another tab will toggle the visibility of this one for the
        next. The tab JavaScript swaps classes to control the content visibility
        and styling.
        <CardTemplate 
          title="Lost clients"
          description="Add a personal message to encourage your lost clients to re-engage with your business.
          *It is recommended to include an incentive such a gift card in your message."
          defaultMessage="Your default retention message."
        />
                <CardTemplate 
          title="Inconsistent clients"
          description="Add a personal message to motivate your irregular clients to re-book an appointment before they fully abandon the business."
          defaultMessage="Your default retention message."
        />
                <CardTemplate 
          title="First time"
          description="Send a personal note to motivate new clients to arrange their next appointment. The message will be sent following a routine frequency determined by the initial service."
          defaultMessage="Your default retention message."
        />
      </Tabs.Item>
      <Tabs.Item disabled title="KEEP ON TRACK - COMING SOON">
        Disabled content
      </Tabs.Item>
    </Tabs.Group>
  );
};

export default FeatureTabs;


//<Button onClick={toggleEmojiPicker}>😊</Button>
//{showPicker && <Picker onEmojiClick={onEmojiClick} />}

