import React, { FC } from 'react';
import { ReactComponent as IncreaseGraphIcon } from '../../../assets/increase-graph-icon.svg'; // Adjust the path as needed
import styles from './BookedAppointments.module.css';

const BookedAppointments: FC = () => {
    return (
        <div className={styles.dashboardGraph}>
            <div>
                <div className={styles.mainStatistics}>330</div>
                <div className={styles.subtitle}>Booked Appointments</div>
            </div>
            <div className={`${styles.progressBarWrapper} text-sm`}>
                <IncreaseGraphIcon className="inline" /> 9% vs. Last month
            </div>
        </div>
    );
};

export default BookedAppointments;
