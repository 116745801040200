// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Notifications_notificationsContainer__xMdQp {
    position: relative;
    display: inline-block;
}

.Notifications_notificationsIcon__tAWVj {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}

.Notifications_notificationCircle__zmiQP {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
}

.Notifications_notificationCount__XoUiC {
    
    height: 16px;
    width: 16px;
    background-color: #DD3E3E;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px; /* Adjust font size as needed */
}`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/Notifications/Notifications.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,+BAA+B;AACnC;;AAEA;;IAEI,YAAY;IACZ,WAAW;IACX,yBAAoD;IACpD,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,eAAe,EAAE,+BAA+B;AACpD","sourcesContent":[".notificationsContainer {\n    position: relative;\n    display: inline-block;\n}\n\n.notificationsIcon {\n    width: 28px;\n    height: 28px;\n    flex-shrink: 0;\n}\n\n.notificationCircle {\n    position: absolute;\n    top: 0;\n    right: 0;\n    transform: translate(50%, -50%);\n}\n\n.notificationCount {\n    \n    height: 16px;\n    width: 16px;\n    background-color: theme('colors.client_segment.red');\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    font-size: 10px; /* Adjust font size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationsContainer": `Notifications_notificationsContainer__xMdQp`,
	"notificationsIcon": `Notifications_notificationsIcon__tAWVj`,
	"notificationCircle": `Notifications_notificationCircle__zmiQP`,
	"notificationCount": `Notifications_notificationCount__XoUiC`
};
export default ___CSS_LOADER_EXPORT___;
