// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.BookedAppointments_dashboardGraph__S4Bjy {
    display: flex;
    flex-direction: column;
    border-left: 8px solid #10B981; /* Example border color */
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: white;
    padding: 1rem;
    white-space: nowrap; /* Prevents text wrapping */
    flex: 4 1;
}

.BookedAppointments_mainStatistics__PYNUN {
    font-size: 2.25rem; /* Equivalent to text-4xl */
    font-weight: bold;
    color: #2C2B4A;
}

.BookedAppointments_subtitle__NYWf0 {
    font-weight: bold;
    color: #6F6E89;
    margin: 0.5rem 0;
}

.BookedAppointments_progressBarWrapper__HlNRo {
    margin-top: auto; /* This pushes the wrapper to the bottom */
    flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/components/RemindersManagement/BookedAppointments/BookedAppointments.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B,EAAE,yBAAyB;IACzD,4BAA4B;IAC5B,+BAA+B;IAC/B,2BAA2B;IAC3B,8BAA8B;IAC9B,uBAAuB;IACvB,aAAa;IACb,mBAAmB,EAAE,2BAA2B;IAChD,SAAO;AACX;;AAEA;IACI,kBAAkB,EAAE,2BAA2B;IAC/C,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,gBAAgB,EAAE,0CAA0C;IAC5D,sBAAsB;AAC1B","sourcesContent":["\n.dashboardGraph {\n    display: flex;\n    flex-direction: column;\n    border-left: 8px solid #10B981; /* Example border color */\n    border-top-right-radius: 8px;\n    border-bottom-right-radius: 8px;\n    border-top-left-radius: 4px;\n    border-bottom-left-radius: 4px;\n    background-color: white;\n    padding: 1rem;\n    white-space: nowrap; /* Prevents text wrapping */\n    flex: 4;\n}\n\n.mainStatistics {\n    font-size: 2.25rem; /* Equivalent to text-4xl */\n    font-weight: bold;\n    color: #2C2B4A;\n}\n\n.subtitle {\n    font-weight: bold;\n    color: #6F6E89;\n    margin: 0.5rem 0;\n}\n\n.progressBarWrapper {\n    margin-top: auto; /* This pushes the wrapper to the bottom */\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardGraph": `BookedAppointments_dashboardGraph__S4Bjy`,
	"mainStatistics": `BookedAppointments_mainStatistics__PYNUN`,
	"subtitle": `BookedAppointments_subtitle__NYWf0`,
	"progressBarWrapper": `BookedAppointments_progressBarWrapper__HlNRo`
};
export default ___CSS_LOADER_EXPORT___;
