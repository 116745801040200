// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.BusinessesDropdown_dropdownButton__c80mA {
    background-color: #2C2A4A; /* Example: dark blue background */
    color: #ffffff; /* White text */
    border-radius: 23px;
    height: 2.5rem;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 8px; /* Space between text and icon */
    color: var(--PURE-WHITE, #FFF);
}

/* Style for the dropdown content */
.BusinessesDropdown_dropdownContent__J8JC9 {
    background-color: #ffffff; /* White background for the dropdown */
    color: #273031; /* Dark text color */
    text-align: left;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for dropdown */
}

/* Style for individual dropdown items */
.BusinessesDropdown_dropdownItem__LoOgW {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    margin: 4px 0;
    cursor: pointer;
    transition: background-color 0.2s;
}

.BusinessesDropdown_dropdownItem__LoOgW:hover {
    background-color: #f3f3f3; /* Light grey background on hover */
}

/* Style for the checkbox within the dropdown */
.BusinessesDropdown_checkbox__KA9Ev {
    margin-right: 8px; /* Space between checkbox and label */
}
`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/BusinessesDropdown/BusinessesDropdown.module.css"],"names":[],"mappings":";AACA;IACI,yBAAyB,EAAE,kCAAkC;IAC7D,cAAc,EAAE,eAAe;IAC/B,mBAAmB;IACnB,cAAW;IACX,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,QAAQ,EAAE,gCAAgC;IAC1C,8BAA8B;AAClC;;AAEA,mCAAmC;AACnC;IACI,yBAAyB,EAAE,sCAAsC;IACjE,cAAc,EAAE,oBAAoB;IACpC,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,wCAAwC,EAAE,6BAA6B;AAC3E;;AAEA,wCAAwC;AACxC;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB,EAAE,mCAAmC;AAClE;;AAEA,+CAA+C;AAC/C;IACI,iBAAiB,EAAE,qCAAqC;AAC5D","sourcesContent":["\n.dropdownButton {\n    background-color: #2C2A4A; /* Example: dark blue background */\n    color: #ffffff; /* White text */\n    border-radius: 23px;\n    @apply h-10;\n    padding: 12px 24px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n    gap: 8px; /* Space between text and icon */\n    color: var(--PURE-WHITE, #FFF);\n}\n\n/* Style for the dropdown content */\n.dropdownContent {\n    background-color: #ffffff; /* White background for the dropdown */\n    color: #273031; /* Dark text color */\n    text-align: left;\n    padding: 8px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for dropdown */\n}\n\n/* Style for individual dropdown items */\n.dropdownItem {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    padding: 8px;\n    border-radius: 4px;\n    margin: 4px 0;\n    cursor: pointer;\n    transition: background-color 0.2s;\n}\n\n.dropdownItem:hover {\n    background-color: #f3f3f3; /* Light grey background on hover */\n}\n\n/* Style for the checkbox within the dropdown */\n.checkbox {\n    margin-right: 8px; /* Space between checkbox and label */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownButton": `BusinessesDropdown_dropdownButton__c80mA`,
	"dropdownContent": `BusinessesDropdown_dropdownContent__J8JC9`,
	"dropdownItem": `BusinessesDropdown_dropdownItem__LoOgW`,
	"checkbox": `BusinessesDropdown_checkbox__KA9Ev`
};
export default ___CSS_LOADER_EXPORT___;
