import React, { FC } from 'react';
import Chart from "react-apexcharts";

// interface ChartProps {
//   clientSegments: {
//     name: string;
//     amount: number;
//     color: string;
//   }[];
// }

const ClientDistributionChart: FC<{ clientSegments: any[] }> = ({ clientSegments }) => {
  const chartData = clientSegments.map(segment => segment.amount);
  const chartColors = clientSegments.map(segment => segment.color); // Using color values from the clientSegments
  // const totalAmount = clientSegments.reduce((acc, segment) => acc + segment.amount, 0);


  return (
    <div className="flex justify-center">
      <Chart
        type="donut"
        options={{
          chart: { 
            type: "donut", 
            width: '100%', 
            height: '100%',
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    showAlways: true,

                    fontSize: '22px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 600,
                    color: '#333'
                  }
                }
              }
            }
          },
          dataLabels: { enabled: false },
          colors: chartColors,
          legend: { show: false },
          labels: clientSegments.map(segment => segment.name),
        }}
        series={chartData}
      />
    </div>
  );
};

export default ClientDistributionChart;
