import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import OverviewPage from "./pages/overview/OverviewPage";
import RemindersEditorPage from "./pages/reminders/RemindersEditorPage"; // Adjust the import path as needed
import RemindersManagementPage from "./pages/reminders/RemindersManagementPage"; // Adjust the import path as needed
import RemindersTest from "./pages/reminders/RemindersTest"; // Adjust the import path as needed
import SideMenu from "./components/SideMenu/SideMenu";
import TopMenuContainer from "./components/TopMenu/TopMenuContainer/TopMenuContainer";
import SignInPage from "./pages/sign-in/Sign-In";
import { SelectionProvider } from "./context/SelectionContext";

const App: React.FC = () => {
  return (
    <SelectionProvider>
      <BrowserRouter>
        <div className="flex">
          <div className="lg:!block hidden">
            <SideMenu />
          </div>
          <div className="dashboard grid grid-rows-32 gap-4">
            <div>
              <TopMenuContainer />
            </div>
            <div>
              <Routes>
                <Route path="/overview" element={<OverviewPage />} />
                <Route
                  path="/reminders-management"
                  element={<RemindersManagementPage />}
                />
                <Route
                  path="/reminders-editor"
                  element={<RemindersEditorPage />}
                />
                <Route path="/reminders-test" element={<RemindersTest />} />
                <Route path="/sign-in" element={<SignInPage />} />
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </SelectionProvider>
  );
};

export default App;
