import React, { FC } from "react";
import Chart from "react-apexcharts";
import styles from "./FilledGaps.module.css";

const FilledGaps: FC = function () {
  return (
    <div className={styles.dashboardGraph}>
      <div className={styles.leftContainer}>
        <div className={styles.statisticsHeader}>
          <span className={styles.filledGaps}>17</span>
          <span className={styles.totalGaps}>/29</span>
        </div>
        <div className={styles.subtitle}>
          Filled last minute cancellation gaps
        </div>
        <div className={styles.indicators}>
          <div className={styles.indicatorWrapper}>
            <div className={styles.indicatorFilled}></div>
            <div className={styles.indicatorText}>Filled</div>
          </div>
          <div className={styles.indicatorWrapper}>
            <div className={styles.indicatorGap}></div>
            <div className={styles.indicatorText}>Gap</div>
          </div>
        </div>
      </div>
      <div className={styles.donutChartContainer}>
        <Chart
          type={"donut"}
          options={{
            chart: { type: "donut", width: '100%', height: '100%'},
            dataLabels: { enabled: false },
            colors: ["#E8EAF6", "#8C55A8"],
            legend: { show: false },
            labels: ["",""],
            plotOptions: {
              pie: {
                expandOnClick: false,
                donut: {
                  size: "80%",
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      showAlways: true,
                      label: "58%",
                      fontWeight: 600,
                      fontSize: "25px",
                      formatter(w: any): string {
                        return "";
                      },
                    },
                    name: {
                      offsetY: 10,
                    },
                  },
                },
              },
            },
          }}
          series={[12, 17]}
        />
      </div>
    </div>
  );
};

export default FilledGaps;
