// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClientsDistributionTable_tableContainer__3Xk4C {
  display: flex;
  justify-content: center;
}

.ClientsDistributionTable_clientsDistributionTable__VodGs {
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-align: left; /* Ensuring text aligns left for all cells */
  border-radius: 16px;
}

/* Styling for table header */
thead th {
  padding: 10px;
  background-color: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
  color: var(--Text--, var(--blue-blue-600, #605E76));
  font-weight: 600;
  line-height: normal;
  text-align: left; /* Explicit left alignment for headers */
  
}

/* Styling for table cells */
tbody td {
  padding: 10px;
  border-bottom: 1px solid #e5e7eb;
  text-align: left; /* Aligning text to the left for consistency */

}

.ClientsDistributionTable_segmentationCell__pvDRz {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligning flex items to start for consistency */
  font-weight: 500;
}

.ClientsDistributionTable_trendCell__-rEt- {
  font-weight: 400;
}

.ClientsDistributionTable_amountCell__VMyIp {
  font-weight: 600;
}

.ClientsDistributionTable_statusIndicator__EtUBv {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ClientsDistribution/ClientsDistributionTable/ClientsDistributionTable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB,EAAE,4CAA4C;EAC9D,mBAAmB;AACrB;;AAEA,6BAA6B;AAC7B;EACE,aAAa;EACb,yBAAyB;EACzB,gCAAgC;EAChC,mDAAmD;EACnD,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB,EAAE,wCAAwC;;AAE5D;;AAEA,4BAA4B;AAC5B;EACE,aAAa;EACb,gCAAgC;EAChC,gBAAgB,EAAE,8CAA8C;;AAElE;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B,EAAE,iDAAiD;EAC9E,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":[".tableContainer {\n  display: flex;\n  justify-content: center;\n}\n\n.clientsDistributionTable {\n  width: 100%;\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n  text-align: left; /* Ensuring text aligns left for all cells */\n  border-radius: 16px;\n}\n\n/* Styling for table header */\nthead th {\n  padding: 10px;\n  background-color: #f9fafb;\n  border-bottom: 1px solid #e5e7eb;\n  color: var(--Text--, var(--blue-blue-600, #605E76));\n  font-weight: 600;\n  line-height: normal;\n  text-align: left; /* Explicit left alignment for headers */\n  \n}\n\n/* Styling for table cells */\ntbody td {\n  padding: 10px;\n  border-bottom: 1px solid #e5e7eb;\n  text-align: left; /* Aligning text to the left for consistency */\n\n}\n\n.segmentationCell {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start; /* Aligning flex items to start for consistency */\n  font-weight: 500;\n}\n\n.trendCell {\n  font-weight: 400;\n}\n\n.amountCell {\n  font-weight: 600;\n}\n\n.statusIndicator {\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableContainer": `ClientsDistributionTable_tableContainer__3Xk4C`,
	"clientsDistributionTable": `ClientsDistributionTable_clientsDistributionTable__VodGs`,
	"segmentationCell": `ClientsDistributionTable_segmentationCell__pvDRz`,
	"trendCell": `ClientsDistributionTable_trendCell__-rEt-`,
	"amountCell": `ClientsDistributionTable_amountCell__VMyIp`,
	"statusIndicator": `ClientsDistributionTable_statusIndicator__EtUBv`
};
export default ___CSS_LOADER_EXPORT___;
