import React, { createContext, useState, FC, ReactNode, useContext } from 'react';
import { Partner, Business } from '../types';

interface SelectionContextProps {
  selectedPartner: Partner | null;
  setSelectedPartner: (partner: Partner | null) => void;
  selectedBusinesses: Business[];
  setSelectedBusinesses: (businesses: Business[]) => void;
}

const SelectionContext = createContext<SelectionContextProps | undefined>(undefined);

interface SelectionProviderProps {
  children: ReactNode;
}

export const SelectionProvider: FC<SelectionProviderProps> = ({ children }) => {
  const [selectedPartner, setSelectedPartner] = useState<Partner | null>(null);
  const [selectedBusinesses, setSelectedBusinesses] = useState<Business[]>([]);

  return (
    <SelectionContext.Provider value={{ selectedPartner, setSelectedPartner, selectedBusinesses, setSelectedBusinesses }}>
      {children}
    </SelectionContext.Provider>
  );
};

export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }
  return context;
};
