import {CustomFlowbiteTheme} from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {

    navbar: {
        root: {
            base: 'bg-white px-2 py-2.5 dark:border-gray-700 dark:bg-gray-800 sm:px-4',
            rounded: {
              on: 'rounded',
              off: '',
            },
            bordered: {
              on: 'border',
              off: '',
            },
            inner: {
              base: 'mx-auto flex flex-wrap items-center justify-between',
              fluid: {
                on: '',
                off: 'container',
              },
            },
          },
          brand: {
            base: 'flex items-center',
          },
          collapse: {
            base: 'w-full md:block md:w-auto',
            list: 'mt-4 flex flex-col md:mt-0 md:flex-row md:space-x-8 md:text-sm md:font-medium',
            hidden: {
              on: 'hidden',
              off: '',
            },
          },
          link: {
            base: 'block py-2 pr-4 pl-3 md:p-0',
            active: {
              on: 'bg-cyan-700 text-white dark:text-white md:bg-transparent md:text-cyan-700',
              off: 'border-b border-gray-100  text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-cyan-700 md:dark:hover:bg-transparent md:dark:hover:text-white',
            },
            disabled: {
              on: 'text-gray-400 hover:cursor-not-allowed dark:text-gray-600',
              off: '',
            },
          },
          toggle: {
            base: 'inline-flex items-center rounded-lg p-2 text-sm text-gray-500 hover:bg-gray-100 focus:outline-none  focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 md:hidden',
            icon: 'h-6 w-6 shrink-0',
          },
    },
    
    
    
    sidebar: {
       root: {
            base: "flex fixed h-screen top-0 rounded-r-2xl z-20 flex-col flex-shrink-0 pt-5 duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700 bg-dark_blue-600 text-gray-200",
            /*collapsed: {
                on: 'w-16',
                off: 'w-64',
              },*/
            inner: "h-full overflow-y-auto overflow-x-hidden rounded pt-13 pl-6 pr-6"
        },
        collapse: {
            button: "group flex w-full items-center p-2 text-base font-normal text-gray-200 transition-full duration-75 hover:text-white hover:bg-dark_blue-500 hover:rounded-full dark:text-white dark:hover:bg-gray-700",
            icon: {
                base: 'ml-2 h-6 w-6 text-gray-200 transition-all duration-75 group-hover:text-white dark:text-gray-400 dark:group-hover:text-white',
                open: {
                  off: '',
                  on: '',
                },
              }, 
            /*label: {
                base: 'ml-3 flex-1 whitespace-nowrap text-left',
                },
            list: 'space-y-2 py-2',*/
              }, 
        /* cta: {
            base: 'mt-6 rounded-lg p-4 bg-gray-100 dark:bg-gray-700',
            color: {
                blue: 'bg-cyan-50 dark:bg-cyan-900',
                dark: 'bg-dark-50 dark:bg-dark-900',
                failure: 'bg-red-50 dark:bg-red-900',
                gray: 'bg-alternative-50 dark:bg-alternative-900',
                green: 'bg-green-50 dark:bg-green-900',
                light: 'bg-light-50 dark:bg-light-900',
                red: 'bg-red-50 dark:bg-red-900',
                purple: 'bg-purple-50 dark:bg-purple-900',
                success: 'bg-green-50 dark:bg-green-900',
                yellow: 'bg-yellow-50 dark:bg-yellow-900',
                warning: 'bg-yellow-50 dark:bg-yellow-900',
            },
            }, */
        item: {
            base: "group flex items-center justify-center rounded-lg p-2 text-base font-normal hover:bg-dark_blue-500 hover:rounded-full dark:text-white dark:hover:bg-gray-700",
            active: 'bg-gray-100 dark:bg-gray-700',
            collapsed: {
              insideCollapse: 'group w-full pl-8 transition duration-75',
              noIcon: 'font-bold',
            },
            content: {
                base: "px-3 flex-1 whitespace-nowrap group-hover:text-white" 
            },
            icon: {
                base: 'ml-2 h-6 w-6 transition-all duration-75 dark:text-gray-400 dark:group-hover:text-white group-hover:text-white',
                active: 'dark:text-gray-200',
              },/* 
            label: '', 
            listItem: '',*/
        },
        items: '',
        itemGroup: 'mt-4 space-y-2 border-t border-gray-200 pt-4 first:mt-0 first:border-t-0 first:pt-0 dark:border-gray-700 text-gray-200',
        logo: {
            base: 'mb-3.5 flex items-center pl-4',
            /*collapsed: {
              on: 'hidden',
              off: 'self-center whitespace-nowrap text-xl font-semibold dark:text-white',
            },*/
            img: "h-6 sm:h-8",
        },
    },


    dropdown: {
       arrowIcon: 'ml-2 h-4 w-4',
       content: 'py-1 focus:outline-none',
       floating: {
         animation: 'transition-opacity',
         arrow: {
           base: 'absolute z-10 h-2 w-2 rotate-45',
           style: {
             dark: 'bg-gray-900 dark:bg-gray-700',
             light: 'bg-white',
             auto: 'bg-white dark:bg-gray-700',
           },
           placement: '-4px',
         },
         base: 'z-10 w-fit rounded divide-y divide-gray-100 shadow focus:outline-none',
         content: 'py-1 text-sm text-gray-700 dark:text-gray-200',
         divider: 'my-1 h-px bg-gray-100 dark:bg-gray-600',
         header: 'block py-2 px-4 text-sm text-gray-700 dark:text-gray-200',
         hidden: 'invisible opacity-0',
         item: {
            //container: '',
           base: 'flex items-center justify-start py-2 px-4 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 focus:outline-none dark:hover:text-white dark:focus:bg-gray-600 dark:focus:text-white',
           icon: 'mr-2 h-4 w-4',
         },
         style: {
           dark: 'bg-gray-900 text-white dark:bg-gray-700',
           light: 'border border-gray-200 bg-white text-gray-900',
           auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
         },
         target: '',
       },
       inlineWrapper: 'flex items-center',
    },


    button: {
      base: 'group flex items-stretch items-center justify-center p-0.5 text-center font-medium relative focus:outline-none focus:ring-0',
      fullSized: 'w-full',
      color: {
        dark: 'text-white bg-gray-800 border border-transparent enabled:hover:bg-gray-900 focus:ring-4 focus:ring-gray-300 dark:bg-gray-800 dark:enabled:hover:bg-gray-700 dark:focus:ring-gray-800 dark:border-gray-700',
        failure:
          'text-white bg-red-700 border border-transparent enabled:hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:enabled:hover:bg-red-700 dark:focus:ring-red-900',
        gray: 'text-gray-900 bg-white border border-gray-200 enabled:hover:bg-gray-100 enabled:hover:text-cyan-700 :ring-cyan-700 focus:text-cyan-700 dark:bg-transparent dark:text-gray-400 dark:border-gray-600 dark:enabled:hover:text-white dark:enabled:hover:bg-gray-700',
        info: 'text-white bg-cyan-700 border border-transparent enabled:hover:bg-cyan-800 focus:ring-4 focus:outline-none dark:bg-cyan-600 dark:enabled:hover:bg-cyan-700 dark:focus:ring-cyan-800',
        light:
          'text-gray-900 bg-white border border-gray-300 enabled:hover:bg-gray-100 focus:ring-4 focus:outline-none dark:bg-gray-600 dark:text-white dark:border-gray-600 dark:enabled:hover:bg-gray-700 dark:enabled:hover:border-gray-700 dark:focus:ring-gray-700',
        purple:
          'text-white bg-purple-700 border border-transparent enabled:hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:bg-purple-600 dark:enabled:hover:bg-purple-700 dark:focus:ring-purple-900',
        success:
          'text-white bg-green-700 border border-transparent enabled:hover:bg-green-800 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:enabled:hover:bg-green-700 dark:focus:ring-green-800',
        warning:
          'text-white bg-yellow-400 border border-transparent enabled:hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-900',
        blue: 'text-white bg-blue-700 border border-transparent enabled:hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800',
        cyan: 'text-cyan-900 bg-white border border-cyan-300 enabled:hover:bg-cyan-100 focus:ring-4 focus:outline-none dark:bg-cyan-600 dark:text-white dark:border-cyan-600 dark:enabled:hover:bg-cyan-700 dark:enabled:hover:border-cyan-700 dark:focus:ring-cyan-700',
        green:
          'text-green-900 bg-white border border-green-300 enabled:hover:bg-green-100 focus:ring-4 focus:ring-green-300 dark:bg-green-600 dark:text-white dark:border-green-600 dark:enabled:hover:bg-green-700 dark:enabled:hover:border-green-700 dark:focus:ring-green-700',
        indigo:
          'text-indigo-900 bg-white border border-indigo-300 enabled:hover:bg-indigo-100 focus:ring-4 focus:ring-indigo-300 dark:bg-indigo-600 dark:text-white dark:border-indigo-600 dark:enabled:hover:bg-indigo-700 dark:enabled:hover:border-indigo-700 dark:focus:ring-indigo-700',
        lime: 'text-lime-900 bg-white border border-lime-300 enabled:hover:bg-lime-100 focus:ring-4 focus:ring-lime-300 dark:bg-lime-600 dark:text-white dark:border-lime-600 dark:enabled:hover:bg-lime-700 dark:enabled:hover:border-lime-700 dark:focus:ring-lime-700',
        pink: 'text-pink-900 bg-white border border-pink-300 enabled:hover:bg-pink-100 focus:ring-4 focus:ring-pink-300 dark:bg-pink-600 dark:text-white dark:border-pink-600 dark:enabled:hover:bg-pink-700 dark:enabled:hover:border-pink-700 dark:focus:ring-pink-700',
        red: 'text-red-900 bg-white border border-red-300 enabled:hover:bg-red-100 focus:ring-4 focus:ring-red-300 dark:bg-red-600 dark:text-white dark:border-red-600 dark:enabled:hover:bg-red-700 dark:enabled:hover:border-red-700 dark:focus:ring-red-700',
        teal: 'text-teal-900 bg-white border border-teal-300 enabled:hover:bg-teal-100 focus:ring-4 focus:ring-teal-300 dark:bg-teal-600 dark:text-white dark:border-teal-600 dark:enabled:hover:bg-teal-700 dark:enabled:hover:border-teal-700 dark:focus:ring-teal-700',
        yellow:
          'text-yellow-900 bg-white border border-yellow-300 enabled:hover:bg-yellow-100 focus:ring-4 focus:ring-yellow-300 dark:bg-yellow-600 dark:text-white dark:border-yellow-600 dark:enabled:hover:bg-yellow-700 dark:enabled:hover:border-yellow-700 dark:focus:ring-yellow-700',
      },
      disabled: 'cursor-not-allowed opacity-50',
      isProcessing: 'cursor-wait',
      spinnerSlot: 'absolute h-full top-0 flex items-center animate-fade-in',
      /*spinnerLeftPosition: {
        xs: 'left-2',
        sm: 'left-3',
        md: 'left-4',
        lg: 'left-5',
        xl: 'left-6',
      },*/
      gradient: {
        cyan: 'text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:outline-none dark:focus:ring-cyan-800',
        failure:
          'text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800',
        info: 'text-white bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 enabled:hover:bg-gradient-to-br focus:ring-4 focus:outline-none dark:focus:ring-cyan-800 ',
        lime: 'text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-lime-300 dark:focus:ring-lime-800',
    
        pink: 'text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-pink-300 dark:focus:ring-pink-800',
        purple:
          'text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800',
        success:
          'text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800',
        teal: 'text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 enabled:hover:bg-gradient-to-br focus:ring-4 focus:ring-teal-300 dark:focus:ring-teal-800',
      },
      gradientDuoTone: {
        cyanToBlue:
          'text-white bg-gradient-to-r from-cyan-500 to-cyan-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:outline-none dark:focus:ring-cyan-800',
        greenToBlue:
          'text-white bg-gradient-to-br from-green-400 to-cyan-600 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-green-200 dark:focus:ring-green-800',
        pinkToOrange:
          'text-white bg-gradient-to-br from-pink-500 to-orange-400 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-pink-200 dark:focus:ring-pink-800',
        purpleToBlue:
          'text-white bg-gradient-to-br from-purple-600 to-cyan-500 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:outline-none dark:focus:ring-cyan-800',
        purpleToPink:
          'text-white bg-gradient-to-r from-purple-500 to-pink-500 enabled:hover:bg-gradient-to-l focus:ring-4 focus:ring-purple-200 dark:focus:ring-purple-800',
        redToYellow:
          'text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 enabled:hover:bg-gradient-to-bl focus:ring-4 focus:ring-red-100 dark:focus:ring-red-400',
        tealToLime:
          'text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 enabled:hover:bg-gradient-to-l enabled:hover:from-teal-200 enabled:hover:to-lime-200 enabled:hover:text-gray-900 focus:ring-4 focus:ring-lime-200 dark:focus:ring-teal-700',
      },
      inner: {
        base: 'flex items-stretch items-center transition-all duration-200',
        position: {
          none: '',
          start: 'rounded-r-none',
          middle: 'rounded-none',
          end: 'rounded-l-none',
        },
        outline: 'border border-transparent',
        /*isProcessingPadding: {
            xs: 'pl-8',
            sm: 'pl-10',
            md: 'pl-12',
            lg: 'pl-16',
            xl: 'pl-20',
        },*/
      },
      label:
        'ml-2 inline-flex h-4 w-4 items-center justify-center rounded-full bg-cyan-200 text-xs font-semibold text-cyan-800',
      outline: {
        color: {
          gray: 'border border-gray-900 dark:border-white',
          default: 'border-0',
          light: '',
        },
        off: '',
        on: 'flex justify-center bg-white text-gray-900 transition-all duration-75 ease-in group-enabled:group-hover:bg-opacity-0 group-enabled:group-hover:text-inherit dark:bg-gray-900 dark:text-white w-full',
        pill: {
          off: 'rounded-md',
          on: 'rounded-full',
        },
      },
      pill: {
        off: 'rounded-lg',
        on: 'rounded-full',
      },
      size: {
        xs: 'text-xs px-2 py-1',
        sm: 'text-sm px-3 py-1.5',
        md: 'text-sm px-4 py-2',
        lg: 'text-base px-5 py-2.5',
        xl: 'text-base px-6 py-3',
      },
    },

    progress: {
      base: 'w-full overflow-hidden rounded-full bg-gray-200 dark:bg-gray-700',
      label: 'mb-1 flex justify-between font-medium dark:text-white',
      bar: 'rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100 space-x-2',
      color: {
        dark: 'bg-gray-600 dark:bg-gray-300',
        blue: 'bg-blue-600',
        red: 'bg-red-600 dark:bg-red-500',
        green: 'bg-green-400 dark:bg-green-500',
        yellow: 'bg-yellow-400',
        indigo: 'bg-indigo-600 dark:bg-indigo-500',
        purple: 'bg-purple-600 dark:bg-purple-500',
        cyan: 'bg-cyan-600',
        gray: 'bg-gray-500',
        lime: 'bg-lime-600',
        pink: 'bg-pink-500',
        teal: 'bg-teal-600',
      },
      size: {
        sm: 'h-1.5',
        md: 'h-2.5',
        lg: 'h-4',
        xl: 'h-6',
      },
    },

    tab:{
      base: 'flex flex-col gap-2',
      tablist: {
          base: 'flex text-center',
          styles: {
              default: 'flex-wrap border-b border-gray-200 dark:border-gray-700',
              underline: 'flex-wrap -mb-px border-b border-gray-200 dark:border-gray-700',
              pills: 'flex-wrap font-medium text-sm text-gray-500 dark:text-gray-400 space-x-2',
              fullWidth: 'w-full text-sm font-medium divide-x divide-gray-200 shadow grid grid-flow-col dark:divide-gray-700 dark:text-gray-400 rounded-none',
          },
          tabitem: {
              base: 'flex items-center justify-center p-4 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 ',
              styles: {
                  default: {
                      base: 'rounded-t-lg',
                      active: {
                          on: 'bg-gray-100 text-cyan-600 dark:bg-gray-800 dark:text-cyan-500',
                          off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800  dark:hover:text-gray-300',
                      },
                  },
                  underline: {
                      base: 'rounded-t-lg',
                      active: {
                          on: 'text-cyan-600 rounded-t-lg border-b-2 border-cyan-600 active dark:text-cyan-500 dark:border-cyan-500',
                          off: 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300',
                      },
                  },
                  pills: {
                      base: '',
                      active: {
                          on: 'rounded-lg bg-cyan-600 text-white',
                          off: 'rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white',
                      },
                  },
                  fullWidth: {
                      base: 'ml-0 first:ml-0 w-full rounded-none flex',
                      active: {
                          on: 'p-4 text-gray-900 bg-gray-100 active dark:bg-gray-700 dark:text-white rounded-none',
                          off: 'bg-white hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 rounded-none',
                      },
                  },
              },
              icon: 'mr-2 h-5 w-5',
          },
      },
      tabpanel: 'py-3',
    },

    badge:
    {
      "root": {
        "base": "flex h-fit items-center gap-1 font-semibold",
        "color": {
          "info": "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-800 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
          "gray": "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600",
          "failure": "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
          "success": "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
          "warning": "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
          "indigo": "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 group-hover:bg-indigo-200 dark:group-hover:bg-indigo-300",
          "purple": "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300",
          "pink": "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 group-hover:bg-pink-200 dark:group-hover:bg-pink-300",
          "blue": "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-900 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
          "cyan": "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-900 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
          "dark": "bg-gray-600 text-gray-100 dark:bg-gray-900 dark:text-gray-200 group-hover:bg-gray-500 dark:group-hover:bg-gray-700",
          "light": "bg-gray-200 text-gray-800 dark:bg-gray-400 dark:text-gray-900 group-hover:bg-gray-300 dark:group-hover:bg-gray-500",
          "green": "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
          "lime": "bg-lime-100 text-lime-800 dark:bg-lime-200 dark:text-lime-900 group-hover:bg-lime-200 dark:group-hover:bg-lime-300",
          "red": "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
          "teal": "bg-teal-100 text-teal-800 dark:bg-teal-200 dark:text-teal-900 group-hover:bg-teal-200 dark:group-hover:bg-teal-300",
          "yellow": "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",

        },
        "href": "group",
        "size": {
          "xs": "p-1 text-xs",
          "sm": "p-1.5 text-sm"
        }
      },
      "icon": {
        "off": "rounded px-2 py-0.5",
        "on": "rounded-full p-1.5",
        "size": {
          "xs": "w-3 h-3",
          "sm": "w-3.5 h-3.5"
        }
      }
    },

    table: 
    {
  "root": {
    "base": "w-full text-left text-sm text-gray-500 dark:text-gray-400",
    "shadow": "absolute bg-white dark:bg-black w-full h-full top-0 left-0 rounded-lg drop-shadow-md -z-10",
    "wrapper": "relative"
  },
  "body": {
    "base": "group/body",
    "cell": {
      "base": "group-first/body:group-first/row:first:rounded-tl-lg group-first/body:group-first/row:last:rounded-tr-lg group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-6 py-4"
    }
  },
  "head": {
    "base": "group/head text-xs uppercase text-gray-700 dark:text-gray-400",
    "cell": {
      "base": "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-gray-50 dark:bg-gray-700 py-3"
    }
  },
  "row": {
    "base": "group/row",
    "hovered": "hover:bg-gray-50 dark:hover:bg-gray-600",
    "striped": "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
  }
},

pagination: 
{
  base: '',
  layout: {
    table: {
      base: 'text-sm text-gray-700 dark:text-gray-400',
      span: 'font-semibold text-gray-900 dark:text-white',
    },
  },
  pages: {
    base: 'xs:mt-0 mt-2 inline-flex items-center -space-x-px',
    showIcon: 'inline-flex',
    previous: {
      base: 'ml-0 rounded-l-lg border border-gray-300 bg-white py-2 px-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    next: {
      base: 'rounded-r-lg border border-gray-300 bg-white py-2 px-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      icon: 'h-5 w-5',
    },
    selector: {
      base: 'w-8 border border-gray-300 bg-white py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white',
      active:
        'bg-cyan-50 text-cyan-600 hover:bg-cyan-100 hover:text-cyan-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white',
      disabled: 'opacity-50 cursor-normal',
    },
  },
},


};

export default flowbiteTheme;
