import React, { FC } from 'react';
import styles from './ClientsDistributionTable.module.css';
import colors from '../../../utils/colors';


const ClientsDistributionTable: FC<{ clientSegments: any[] }> = ({ clientSegments }) => {
  return (
    <div className={styles.tableContainer}>
      <table className={styles.clientsDistributionTable}>
        <thead style={{ color: colors.darkBlue500 }}>
          <tr>
            <th>SEGMENTATION</th>
            <th>TREND</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {clientSegments.map(segment => (
            <tr key={segment.name}>
              <td className={styles.segmentationCell}>
              <div className={styles.statusIndicator} style={{ backgroundColor: segment.color }} />
              {segment.name}
              </td>
              <td className={styles.trendCell} style={{ color: colors.darkBlue500 }}>{segment.trend}</td>
              <td className={styles.amountCell}>{segment.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsDistributionTable;
