import React, { FC } from "react";
import SkeletonLoader from "../../SkeletonLoader/SkeletonLoader";
import { ReactComponent as IncreaseGraphIcon } from "../../../assets/increase-graph-icon.svg";
import styles from "./RevenueGained.module.css";

interface RevenueGainedProps {
  data: {
    revenue: number;
    percentageChange: number;
  } | null; // data can be null to indicate no business selected
}

const RevenueGained: FC<RevenueGainedProps> = ({ data }) => {
  if (!data) {
    return (
      <div className={styles.dashboardGraph}>
        <SkeletonLoader type="default" rows={3} />
      </div>
    );
  }

  return (
    <div className={styles.dashboardGraph}>
      <div>
        <div className={styles.mainStatistics}>
          ${data.revenue.toLocaleString()}
        </div>
        <div className={styles.subtitle}>Revenue gained</div>
      </div>
      <div className={`${styles.progressBarWrapper} text-sm`}>
        <IncreaseGraphIcon className="inline" /> {data.percentageChange}% vs.
        Last month
      </div>
    </div>
  );
};

export default RevenueGained;
