import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "flowbite-react";
import PartnerDropdown from "./PartnerDropdown/PartnerDropdown";
import styles from "./SideMenu.module.css";

// Import icons
import { ReactComponent as OverviewIcon } from "../../assets/overview-icon.svg";
import { ReactComponent as ResultIcon } from "../../assets/result-icon.svg";
import { ReactComponent as StaffIcon } from "../../assets/staff-icon.svg";
import { ReactComponent as RemindersIcon } from "../../assets/reminders-icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings-icon.svg";
import { ReactComponent as AdjustmentsIcon } from "../../assets/adjustments-icon.svg";
import { ReactComponent as GlobeIcon } from "../../assets/globe-icon.svg";

const SideMenu: FC = function () {
  const partners = [
    { id: "point", name: "Point" },
    // { id: "vcita", name: "vCita" },
    // Add more partners here
  ];
  return (
    <Sidebar>
      <div className="side-menu">
        <div>
          <Sidebar.Logo
            img={"/logo-white.svg"}
            imgAlt={"Apptor Logo"}
            href={"#"}
          />
          <div className={styles.separator}></div>
          <Sidebar.Items>
            <Sidebar.ItemGroup>
              <Sidebar.Item as={Link} to="/overview" icon={OverviewIcon}>
                <p>Overview</p>
              </Sidebar.Item>
              <Sidebar.Collapse label={"Results"} icon={ResultIcon}>
                <Sidebar.Item>Client segmentation</Sidebar.Item>
                <Sidebar.Item>Impact</Sidebar.Item>
                <Sidebar.Item>Insights</Sidebar.Item>
              </Sidebar.Collapse>
              <Sidebar.Item icon={StaffIcon}>
                <p>Staff</p>
              </Sidebar.Item>
              <Sidebar.Collapse label={"Reminders"} icon={RemindersIcon}>
                <Sidebar.Item as={Link} to="/reminders-management">
                  Management
                </Sidebar.Item>
                <Sidebar.Item as={Link} to="/reminders-editor">
                  Editor
                </Sidebar.Item>
                <Sidebar.Item>Staff Churn</Sidebar.Item>
              </Sidebar.Collapse>
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>
        <div>
          <div className={styles.separator}></div>
          <div className="bg-dark_blue-500 rounded-full mb-4">
            <Sidebar.ItemGroup>
            <PartnerDropdown partners={partners} />
            </Sidebar.ItemGroup>
          </div>
          <div className={styles.bottomIcons}>
            <div className="side-menu-bottom-button">
              <AdjustmentsIcon />
            </div>
            <div className="side-menu-bottom-button">
              <SettingsIcon />
            </div>
            <div className="side-menu-bottom-button">
              <GlobeIcon />
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SideMenu;
