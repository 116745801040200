import React, { FC } from "react";
import FeatureTabs from '../../components/RemindersEditor/FeatureTabs/FeatureTabs';

const RemindersEditorPage : FC = function () {
    return (
        <FeatureTabs />

    );
};

export default RemindersEditorPage;