import React, { FC, useEffect } from 'react';
import styles from './SkeletonLoader.module.css'; // Ensure this path is correct

interface SkeletonLoaderProps {
  type?: 'default' | 'chart';
  rows?: number; // Number of rows for the skeleton
}

const SkeletonLoader: FC<SkeletonLoaderProps> = ({ type = 'default', rows = 5 }) => {
  // Function to set random widths for specific rows
  const setRandomWidths = () => {
    const skeletonRows = document.querySelectorAll(`.${styles.skeletonLoader} div`);
    skeletonRows.forEach((row, index) => {
      if (index >= 2 && index < skeletonRows.length - 1) { // For third row and onwards
        // Cast each row as HTMLElement
        const htmlRow = row as HTMLElement;
        htmlRow.style.setProperty('--random-width', Math.random().toFixed(2));
      }
    });
  };

  // useEffect to apply random widths after rendering
  useEffect(() => {
    if (type === 'default') {
      setRandomWidths();
    }
  }, [type]);

  const renderRows = () => {
    const rowElements = [];
    for (let i = 0; i < rows; i++) {
      rowElements.push(<div key={i} className={styles.skeletonRow}></div>);
    }
    return rowElements;
  };

  const renderDefaultSkeleton = () => (
    <div className={styles.skeletonLoader}>
      {renderRows()}
    </div>
  );

  const renderChartSkeleton = () => (
    <div role="status" className={styles.chartSkeleton}>
      {/* Chart skeleton HTML */}
      <span className="sr-only">Loading...</span>
    </div>
  );

  return type === 'chart' ? renderChartSkeleton() : renderDefaultSkeleton();
};

export default SkeletonLoader;
